// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTableContainer{overflow:auto}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable{min-width:420px}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable td:last-child button{min-width:60px !important}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable th,.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable td{padding:6px}@media only screen and (min-width: 1400px){.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable th,.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable td{padding:8px}}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable tbody button svg{color:#989898}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable tbody button:hover{background-color:hsla(0,0%,60%,.2)}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable tfoot>:last-child>:nth-child(2) input{text-align:right}.app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable tfoot .MuiInputBase-root::before{border-bottom:solid 1px rgba(86,100,110,.2) !important}.app-pages-MaterialPage-MasterData-BatchesTable-styles-InputFieldNumber input{text-align:right}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/MasterData/BatchesTable/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAIA,6EACC,aAAA,CAGD,oEACC,eAAA,CAEA,yFACC,yBAAA,CAGD,8ICmEA,WAAA,CAlBA,2CDjDA,8ICqEC,WAAA,CAAA,CDhEA,qFACC,aAAA,CAGD,uFACC,kCAAA,CAKD,0GACC,gBAAA,CAID,qGACC,sDAAA,CAKH,8EACC,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BatchesTableContainer": "app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTableContainer",
	"BatchesTable": "app-pages-MaterialPage-MasterData-BatchesTable-styles-BatchesTable",
	"InputFieldNumber": "app-pages-MaterialPage-MasterData-BatchesTable-styles-InputFieldNumber"
};
export default ___CSS_LOADER_EXPORT___;
