// DonutChart.js
import React from 'react';
import { donut } from 'billboard.js';
import _ from 'lodash';
import { capitalizeAll } from 'utils/format';
import { useChart } from './useChart';

const DonutChart = ({ data, containerId, formatValue = value => value, valueLabel = '', height }) => {

	const colors = ['#5B63B6', '#acaec7', '#00C2AF', '#FFA900'];
	const dataKeys = Object.keys(data); // Use Object.keys instead of _.keys initially

	const config = {
		data: {
			columns: _.map(data, (value, key) => [key, value]),
			colors: _.mapValues(data, (_, key) => colors[dataKeys.indexOf(key) % colors.length]),
			names: _.mapValues(data, (_, key) => capitalizeAll(key))
		},
		tooltip: {
			format: { value: value => `${formatValue(value)}${valueLabel}` }
		},
		size: height ? { height } : undefined
	};

	useChart({ config, data, containerId, type: donut() });
	return <div id={containerId} className="donut-chart" />;
};

export default DonutChart;
