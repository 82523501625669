import { race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { CALCULATE } from './actions';

export default function* notificationSaga() {
	while (true) {
		const { calculateSuccess, calculateFailure } = yield race({
			calculateSuccess: take([CALCULATE.SUCCESS]),
			calculateFailure: take([CALCULATE.FAILURE])
		});

		if (calculateSuccess) {
			toast.success('Simulation successful');
		}

		if (calculateFailure) {
			toast.error('Calculation failed');
		}
	}
}
