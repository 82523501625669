import { Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect } from 'react';
import tableStyle from 'components/Table/default.scss';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';
import { MaterialDatePicker } from 'components/input/MaterialDatePicker';
import { MaterialNumberField, MaterialTextField } from 'components/input/MaterialTextField';
import { useFormContext } from 'react-hook-form';

export const BatchesTable = () => {
	const { control, watch, setValue, getValues, setError } = useFormContext();

	// initiating necessary form value
	const shelfLifeInventory = watch('shelfLifeInventory', [])

	// Ensure that the shelfLifeInventory is always sorted by expiry date
	useEffect(() => {
		const newShelfLifeInventory = [...shelfLifeInventory].sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate));
		const isDifferent = newShelfLifeInventory.some((item, index) => item !== shelfLifeInventory[index]);
		if (isDifferent) {
			setValue('shelfLifeInventory', newShelfLifeInventory);
		}

	}, [shelfLifeInventory]);

	const deleteBatch = (index) => {
		const newShelfLifeInventory = shelfLifeInventory.filter((_, i) => i !== index);
		setValue('shelfLifeInventory', newShelfLifeInventory);
	};

	const addBatch = () => {
		// get current form values and throw error if they are empty
		const batchnr = getValues('newBatchNumber') || setError('newBatchNumber', { type: 'manual', message: 'required' });
		const quantity = getValues('newBatchQuantity') || setError('newBatchQuantity', { type: 'manual', message: 'required' });
		const expiryDate = getValues('newBatchExpiryDate') || setError('newBatchExpiryDate', { type: 'manual', message: 'required' });
		const statusKey = getValues('newBatchStatusKey') || setError('newBatchStatusKey', { type: 'manual', message: 'required' });

		const newBatch = { batchnr, quantity, expiryDate, statusKey };
		const allValuesTruthy = Object.values(newBatch).every(value => Boolean(value));

		if (allValuesTruthy) {

			const newShelfLifeInventory = [...shelfLifeInventory, newBatch]
			setValue('shelfLifeInventory', newShelfLifeInventory);

			// reset the form fields
			setValue('newBatchNumber', '');
			setValue('newBatchQuantity', '');
			setValue('newBatchExpiryDate', '');
			setValue('newBatchStatusKey', '');
		}
	};

	return (
		<>
			<TableContainer className={styles.BatchesTableContainer}>
				<Table className={mergeClasses(styles.BatchesTable, tableStyle.default)}>
					<TableHead>
						<TableRow>
							<TableCell>Number</TableCell>
							<TableCell style={{ width: '90px' }}>Quantity</TableCell>
							<TableCell>Expiry</TableCell>
							<TableCell style={{ width: '70px' }}>Status</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{shelfLifeInventory.map((line, index) => (
							<TableRow key={line.batchnr + index}>
								<TableCell>
									<MaterialTextField name={`shelfLifeInventory[${index}].batchnr`} control={control} />
								</TableCell>
								<TableCell>
									<MaterialTextField name={`shelfLifeInventory[${index}].quantity`} control={control} className={styles.InputFieldNumber} />
								</TableCell>
								<TableCell>
									<MaterialDatePicker name={`shelfLifeInventory[${index}].expiryDate`} control={control} />
								</TableCell>
								<TableCell>
									<MaterialTextField name={`shelfLifeInventory[${index}].statusKey`} control={control}
																		 className={styles.InputFieldNumber} />
								</TableCell>
								<TableCell align="center">
									<Button onClick={() => deleteBatch(index)}>
										<DeleteIcon />
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell>
								<MaterialTextField name="newBatchNumber" control={control} />
							</TableCell>
							<TableCell>
								<MaterialNumberField name="newBatchQuantity" control={control} />
							</TableCell>
							<TableCell>
								<MaterialDatePicker name="newBatchExpiryDate" control={control} />
							</TableCell>
							<TableCell>
								<MaterialTextField name="newBatchStatusKey" control={control} />
							</TableCell>
							<TableCell align="center">
								<Button color="primary" variant="outlined" size="small"
												onClick={addBatch}>
									Add
								</Button>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
};
