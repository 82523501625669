// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-CO2-styles-Results>div>div{align-items:center;border-bottom:1px solid #eaeefd;border-right:1px solid #eaeefd;display:flex;text-align:right;justify-content:flex-end;overflow:hidden;white-space:nowrap;padding:12px}@media only screen and (min-width: 1400px){.app-pages-MaterialPage-CO2-styles-Results>div>div{padding:16px}}.app-pages-MaterialPage-CO2-styles-Results>div>div:first-child{font-size:.8125rem}@media only screen and (min-width: 1400px){.app-pages-MaterialPage-CO2-styles-Results>div>div:first-child{font-size:.9375rem}}.app-pages-MaterialPage-CO2-styles-Results>div>div:last-child{border-bottom:none}.app-pages-MaterialPage-CO2-styles-Results>div:first-child>div{padding-left:0;justify-content:flex-start}.app-pages-MaterialPage-CO2-styles-Results>div:last-child>div{border-right:none;padding-right:0}.app-pages-MaterialPage-CO2-styles-Unit{font-size:10px;letter-spacing:0;margin-left:2px;vertical-align:top}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/CO2/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAEC,mDACC,kBAAA,CACA,+BAAA,CACA,8BAAA,CACA,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,eAAA,CACA,kBAAA,CCwED,YAAA,CAlBA,2CD9DA,mDCkFC,YAAA,CAAA,CDvEA,+DCwGD,kBAAA,CArDA,2CDnDC,+DC2GA,kBAAA,CAAA,CDvGA,8DACC,kBAAA,CAIF,+DACC,cAAA,CACA,0BAAA,CAGD,8DACC,iBAAA,CACA,eAAA,CAIF,wCACC,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Results": "app-pages-MaterialPage-CO2-styles-Results",
	"Unit": "app-pages-MaterialPage-CO2-styles-Unit"
};
export default ___CSS_LOADER_EXPORT___;
