// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-InventoryVisualizationPage-styles-Body{padding:12px}@media only screen and (min-width: 1400px){.app-pages-InventoryVisualizationPage-styles-Body{padding:16px}}.app-pages-InventoryVisualizationPage-styles-Title{background:#5b63b6;display:flex;justify-content:center;align-items:center;padding:0;transition:.2s;border-radius:4px 4px 0 0 !important}.app-pages-InventoryVisualizationPage-styles-Title img{filter:invert(100%)}.app-pages-InventoryVisualizationPage-styles-Title .app-pages-InventoryVisualizationPage-styles-Text{margin-right:auto;font-weight:bold}.app-pages-InventoryVisualizationPage-styles-Title .app-pages-InventoryVisualizationPage-styles-Logo{width:70px;padding:0 20px}.app-pages-InventoryVisualizationPage-styles-Title .app-pages-InventoryVisualizationPage-styles-Toggle{padding:0 20px;transition:.5s;width:60px;height:60px;color:#fff}.app-pages-InventoryVisualizationPage-styles-Content{margin:12px}@media only screen and (min-width: 1400px){.app-pages-InventoryVisualizationPage-styles-Content{margin:16px}}", "",{"version":3,"sources":["webpack://./app/pages/InventoryVisualizationPage/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAMA,kDC4EC,YAAA,CAlBA,2CD1DD,kDC8EE,YAAA,CAAA,CD1EF,mDACC,kBCCY,CAAA,YAAA,CDCZ,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,cAAA,CACA,oCAAA,CAEA,uDACC,mBAAA,CAGD,qGACC,iBAAA,CACA,gBAAA,CAGD,qGACC,UAAA,CACA,cAAA,CAGD,uGACC,cAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CC2DA,qDACC,WAAA,CAlCF,2CDtBD,qDC2DG,WAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Body": "app-pages-InventoryVisualizationPage-styles-Body",
	"Title": "app-pages-InventoryVisualizationPage-styles-Title",
	"Text": "app-pages-InventoryVisualizationPage-styles-Text",
	"Logo": "app-pages-InventoryVisualizationPage-styles-Logo",
	"Toggle": "app-pages-InventoryVisualizationPage-styles-Toggle",
	"Content": "app-pages-InventoryVisualizationPage-styles-Content"
};
export default ___CSS_LOADER_EXPORT___;
