import _ from 'lodash';

import { CALL_API, createRequestTypes } from 'modules/api';
import { getSearchParameter } from 'utils/url';

let previousFilters = [];
export const REQUEST_STORAGE_OCCUPATION = createRequestTypes('REQUEST_STORAGE_OCCUPATION');
export const requestStorageData = (filters = []) => {
	previousFilters = filters;
	return {
		type: CALL_API,
		endpoint: `/company/${getSearchParameter('company') || 'me'}/storage/occupation?${_.map(filters, (v, k) =>
			v.length ? `${k}=${JSON.stringify(v)}` : '').join('&')}`,
		method: 'GET',
		callType: REQUEST_STORAGE_OCCUPATION
	};
};

// #FIXME Pattern broke when wanting to refresh StorageChart from StorageTable. Filters should be move from state to redux state.
export const refreshStorageData = () => requestStorageData(previousFilters);
