/**
 * Opportunities table selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectOrdersList = state => state.get('Material') || initialState;

export const selectMaterial = () =>
	createSelector(
		selectOrdersList,
		state => state.material
	);
export const selectFormMaterial = () =>
	createSelector(
		selectOrdersList,
		state => state.formMaterial
	);

export const selectMaterialSim = () =>
	createSelector(
		selectOrdersList,
		state => state.materialSim
	);

export const selectSimulatedMaterial = () =>
	createSelector(
		selectOrdersList,
		state => state.simulatedMaterial
	);

export const selectSentMaterial = () =>
	createSelector(
		selectOrdersList,
		state => state.sentMaterial
	);

export const selectLoading = () =>
	createSelector(
		selectOrdersList,
		state => state.loading
	);
