/**
 *
 * Header
 *
 */

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import EcoIcon from '@material-ui/icons/Eco';
import WarningIcon from '@material-ui/icons/Warning';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { LinkWithQuery } from 'components/Link';
import Logo from 'components/Logo';
import config from 'config.js';
import { formatUTCDateTime } from 'utils/dates';
import { useQuery } from 'utils/url';
import hoc from './hoc';
import InventoryVisualizationSvg from './images/InventoryVisualization.svg';
import OpportunitySvg from './images/Opportunity.svg';
import OrderPlannerSvg from './images/OrderPlanner.svg';
import SafetyStockOptimizerSvg from './images/SafetyStockOptimizer.svg';
import SimulatorSvg from './images/Simulator.svg';
import styles from './styles.scss';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

const Header = ({ user, company, companyConfig, companies, getUser, logout, features, showJoyride}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [showDates, setShowDates] = useState(false);
	const open = Boolean(anchorEl);
	const path = window.location.pathname;
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const query = useQuery();
	const handleMenu = event => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleCompanyChange = (newCompany) => {
		const params = new URLSearchParams(searchParams)
		params.set('company', newCompany)
		const pathName = location.pathname.startsWith('/material/') ? '/orders' : location.pathname
		return `${pathName}?${params.toString()}`
	};

	if (!user) return <div />;

	const threshold = features.expiredDataThreshold;
	const cfg = companyConfig;

	const isExpired = date => {
		const diff = -dayjs.utc(date).diff(dayjs.utc(), 'hours');
		return threshold && (!_.isNumber(diff) || Number.isNaN(diff) || diff > threshold);
	};
	const processed = isExpired(cfg.processed_datetime) || isExpired(cfg.import_datetime);
	const expired = processed || isExpired(cfg.data_datetime);

	return (
		<Drawer id="gl-menu" className={styles.Drawer} variant="permanent" anchor="left">
			<List className={styles.Logo} disablePadding>
				<ListItem button component={LinkWithQuery} to="/">
					<Logo color="#ffffff" inverted />
					<div className={styles.Mask} />
				</ListItem>
			</List>
			<List disablePadding>
				<ListItem id="gl-menu-orders" button selected={path === '/orders'} component={LinkWithQuery} to="/orders">
					<ListItemIcon>
						<img src={OrderPlannerSvg} alt="Orders" />
					</ListItemIcon>
					<ListItemText primary="Orders" />
				</ListItem>
				<ListItem
					button
					selected={path === '/safetystock'}
					component={LinkWithQuery}
					style={features.showSafetyStockOptimizer ? {} : { display: 'none' }}
					to="/safetystock">
					<ListItemIcon>
						<img src={SafetyStockOptimizerSvg} alt="Safety Stock" />
					</ListItemIcon>
					<ListItemText primary="Safety Stock" />
				</ListItem>
				<ListItem
					button
					selected={path === '/inventory'}
					component={LinkWithQuery}
					style={features.inventoryVisualization ? {} : { display: 'none' }}
					to="/inventory">
					<ListItemIcon>
						<img src={InventoryVisualizationSvg} alt="Inventory" />
					</ListItemIcon>
					<ListItemText primary="Inventory" />
				</ListItem>
				<ListItem button selected={path === '/reports/opportunities'} component={LinkWithQuery} to="/reports/opportunities">
					<ListItemIcon>
						<img src={OpportunitySvg} alt="Opportunities" />
					</ListItemIcon>
					<ListItemText primary="Reports" />
				</ListItem>
				<ListItem
					button
					selected={path === '/simulator'}
					component={LinkWithQuery}
					style={features.simulator.show ? {} : { display: 'none' }}
					to="/simulator">
					<ListItemIcon>
						<img src={SimulatorSvg} alt="Simulator" />
					</ListItemIcon>
					<ListItemText primary="Simulator" />
				</ListItem>
				<ListItem button selected={path === '/reports/co2'} component={LinkWithQuery} to="/reports/co2">
					<ListItemIcon>
						<EcoIcon style={{ color: 'white' }} />
					</ListItemIcon>
					<ListItemText primary="CO2" />
				</ListItem>
				<ListItem button selected={path === '/reports/value-tracker'}
									component={LinkWithQuery} to="/reports/value-tracker"
									style={features.material.autoSave ? {} : { display: 'none' }}>
					<ListItemIcon>
						<DonutLargeIcon style={{ color: 'white' }} />
					</ListItemIcon>
					<ListItemText primary="Value Tracker" />
				</ListItem>
			</List>
			<List className={styles.Bottom} disablePadding>
				<ListItem button onClick={() => Userback.open()}>
					<ListItemIcon>
						<ChatBubbleOutline style={{ fontSize: '24px', color: 'white' }} />
					</ListItemIcon>
					<ListItemText secondary="Give feedback" />
				</ListItem>
				<ListItem button onClick={handleMenu}>
					<ListItemIcon>
						{expired ? (
							<WarningIcon color="error" style={!processed ? { color: '#FFA900' } : {}} />
						) : (
							<Avatar style={{ width: '28px', height: '28px', fontSize: '11px', marginLeft: '-4px' }}>
								{user.match(/\b\w/g).join('')}
							</Avatar>
						)}
					</ListItemIcon>
					{/* <ListItemText primary={user} secondary={window.ccompany || company} /> */}
					<ListItemText secondary={query.get('company') || company} />
					<ArrowDropDown style={{ color: 'white' }} />
				</ListItem>
				<Menu
					className={styles.Menu}
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					open={open}
					onClose={handleClose}>
					<div>
						<MenuItem disabled className={styles.Title}>
							{query.get('company') || company}
						</MenuItem>
						<MenuItem className={styles.Small} onClick={() => setShowDates(!showDates)}>
							{showDates ? (
								<>
									<div>Data from {cfg.data_datetime ? formatUTCDateTime(cfg.data_datetime) : '-'}</div>
									<div>Import from {cfg.import_datetime ? formatUTCDateTime(cfg.import_datetime) : '-'}</div>
									<div>Calculations from {cfg.processed_datetime ? formatUTCDateTime(cfg.processed_datetime) : '-'}</div>
								</>
							) : (
								<>
									<div>Data from {cfg.data_datetime ? dayjs.utc(cfg.data_datetime).fromNow() : '-'}</div>
									<div>Import from {cfg.import_datetime ? dayjs.utc(cfg.import_datetime).fromNow() : '-'}</div>
									<div>Calculations from {cfg.processed_datetime ? dayjs.utc(cfg.processed_datetime).fromNow() : '-'}</div>
								</>
							)}
							<div>Version {config.version}</div>
						</MenuItem>
						<Divider />
					</div>
					{companies.length > 0 && (
						<div>
							<MenuItem disabled className={styles.Title}>
								Companies
							</MenuItem>
							{companies.map(c => (
								<MenuItem
									component={Link}
									to={handleCompanyChange(c)}
									key={c}
									className={styles.Company}
									onClick={() => {
										getUser(c);
										handleClose();
									}}>
									{c}
								</MenuItem>
							))}
							<Divider />
						</div>
					)}
					<MenuItem disabled className={styles.Title}>
						{user}
					</MenuItem>
					{features.productTour && (
						<MenuItem
							onClick={() => {
								handleClose();
								showJoyride(true);
							}}>
							Take a tour of GenLots
						</MenuItem>
					)}
					<MenuItem onClick={() => logout()}>Sign out</MenuItem>
				</Menu>
			</List>
		</Drawer>
	);
};

Header.propTypes = {
	company: PropTypes.string,
	companies: PropTypes.array,
	companyConfig: PropTypes.object,
	features: PropTypes.object,
	logout: PropTypes.func.isRequired,
	user: PropTypes.string
};

export default hoc(Header);
