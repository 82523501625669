import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import Undo from '@material-ui/icons/Undo';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatDate } from 'utils/dates';
import { getPurReqGLfromCalculation } from '../helpers';

import MenuButton from 'components/button/MenuButton';
import ToggleButton from 'components/button/ToggleButton';
import XSButton from 'components/button/XSButton';
import { MaterialDatePicker } from 'components/input/MaterialDatePicker';
import { MaterialNumberField } from 'components/input/MaterialTextField';
import { Text } from 'components/Text';
import { ellipsisCell, ReactTable } from 'components/ReactTable';
import { downloadTableXLSX } from 'components/ReactTable/helpers';
import Spacer from 'components/Spacer';
import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';
import { toast } from 'react-toastify';
import { ValidateSuccess } from '../messages';


function OrdersTable({
											 config, features, material, updateMaterial,
											 recalculateOnReset, setRecalculateOnReset,
											 resetInProgress, setResetInProgress, loading, reset, disabled,
											 ...rest
										 }) {
	const { control, watch, setValue, getValues, setError, reset: resetForm } = useFormContext();
	const [currentTab, setCurrentTab] = useState('GenLots');
	const [orderMovements, setOrderMovements] = useState([]);


	const { supplierLeadTime, unit } = material;
	const movements = watch('movements');

	useEffect(() => {
		const onlyOrdersFilter = [...new Set([
			...config.fixedOrders.filter(order => !config.genLotsOrders.includes(order)),
			...(currentTab === 'GenLots' ? config.genLotsOrders : config.unfixedOrders),
			...config.qmLots])];

		if (movements) {
			const newOrderMovements = movements.filter((mov, index) => {
				if (onlyOrdersFilter.includes(mov.mrpElement)) {
					mov.originalIndex = index;
					return true;
				}
				return false;
			});

			setOrderMovements(newOrderMovements);
		}
	}, [movements, currentTab]);

	useEffect(() => {
		// Make sure that we reset the form when the orderMovements change
		const currentValues = { ...getValues() };
		if (orderMovements && !_.isEqual(orderMovements, currentValues.orderMovements)) {
			currentValues.orderMovements = orderMovements;
			resetForm(currentValues);
		}
	}, [orderMovements]);


	useEffect(() => {
		console.log('resetInProgress', resetInProgress, 'loading', loading, 'recalculateOnReset', recalculateOnReset);
		if (resetInProgress && !loading && !recalculateOnReset) {
			// once lodading has been finihshed, reset the resetInProgress flag and do a normal reset where we request update
			reset();
			setResetInProgress(false);
		}
		if (resetInProgress && !loading && recalculateOnReset) {
			// first reset to the original calculation values (with save in db if autosave)
			resetToOriginal();
			setRecalculateOnReset(false);
		}

	}, [resetInProgress, loading, recalculateOnReset]);

	if (movements === undefined) return null;

	const handleChange = (e, newValue) => newValue && setCurrentTab(newValue);


	const typeToColor = row => {
		if (config.fixedOrders.includes(row.mrpElement)) {
			return 'rgb(86,100,110)';
		}
		if (config.qmLots.includes(row.mrpElement)) {
			return 'black';
		}
		if (config.genLotsOrders.includes(row.mrpElement)) {
			return '#5B63B6';
		}
		return 'lightgray';
	};


	const editableDateCell = ({ original, column, value, index }) => {
		if (original.canBeValidated) {
			const name = `orderMovements[${index}].${column.id}`;
			return <MaterialDatePicker name={name} control={control} defaultValue={value} />;
		}
		return formatDate(value);
	};

	const editableQuantityCell = ({ original, column, value, index }) => {
		if (original.canBeValidated) {
			const name = `orderMovements[${index}].${column.id}`;
			return (
				<MaterialNumberField
					name={name}
					size="medium"
					defaultValue={value}
					className={styles.TextField}
					control={control}
				/>
			);
		}
		return value;
	};

	const columns = [
			{
				width: 13,
				headerClassName: styles.FirstColumn,
				className: styles.FirstColumn,
				Cell: ({ row }) => <div style={{ background: typeToColor(row._original) }} />
			},
			{ accessor: 'deliveryDate', Header: 'Delivery', minWidth: 96, Cell: editableDateCell },
			{
				id: 'orderDate',
				accessor: o => formatDate(dayjs(o.deliveryDate).add(-supplierLeadTime || 0, 'days')),
				Header: 'Opening',
				minWidth: 70
			},
			{
				accessor: 'mrpElement',
				Header: 'MRP element',
				minWidth: 80,
				Cell: ({ value }) => value
			},
			{ accessor: 'mrpElementData', Header: 'Number', minWidth: 65, Cell: ellipsisCell },
			{
				accessor: 'quantity',
				Header: `Quantity (${unit || ''})`,
				Cell: editableQuantityCell,
				minWidth: 70
			},
			{
				width: 70,
				className: styles.NoPadding,
				Cell: ({ original }) => {
					if (original.canBeValidated) {
						return (
							<ButtonGroup size="small">
								<XSButton title="Validate suggestion" disabled={disabled} variant="contained" onClick={() => validate(original)}>
									<Check />
								</XSButton>
							</ButtonGroup>
						)
							;
					}
					if (original.cancelForSimulation) {
						return (
							<ButtonGroup size="small">
								<XSButton title="Simulate unfixed" disabled={disabled} onClick={() => simulateUnfixed(original)}>
									<Undo />
								</XSButton>
							</ButtonGroup>

						);
					}

					if (original.mrpElement === 'POItemGL') {
						return (
							<ButtonGroup size="small">
								<XSButton title="Remove order (reset with recalculate necessary)" disabled={disabled} onClick={() => remove(original)}>
									<ClearIcon />
								</XSButton>
							</ButtonGroup>
						);
					}
					return '';

				}
			}

		]
	;

	const save = (updatedMovements) => {
		setValue('movements', updatedMovements);

		if (features.material?.autoSave) {
			const reducedMovements = updatedMovements.map(({ deliveryDate, mrpElement, mrpElementData, quantity, revertTo }) => (
				{ deliveryDate, mrpElement: revertTo || mrpElement, mrpElementData, quantity }
			));
			updateMaterial(material.id, { movements: reducedMovements });
		}
	};
	const resetToOriginal = () => {
		const movementsWithoutGenLots = movements.filter(m => !['PurReqGL', 'POItemGL'].includes(m.mrpElement));
		const originalCalculatedPurReqGL = getPurReqGLfromCalculation(material.calculation, material.frequency);
		const updatedMovements = [...movementsWithoutGenLots, ...originalCalculatedPurReqGL];
		save(updatedMovements);
	};

	const validate = row => {

		const updatedMovements = [...movements];
		const deliveryDate = getValues(`orderMovements[${row.index}].deliveryDate`);
		const quantity = getValues(`orderMovements[${row.index}].quantity`);

		if (!quantity || quantity < 0) {
			setError(`orderMovements[${row.index}].quantity`,
				{ type: 'manual', message: 'Invalid Quantity' });
			return;
		}

		if (!deliveryDate || !dayjs(deliveryDate).isValid()) {
			setError(`orderMovements[${row.index}].deliveryDate`,
				{ type: 'manual', message: 'Invalid Date' });
			return;
		}
		const newMovement = {
			...movements[row.originalIndex],
			mrpElement: 'POItemGL',
			quantity: quantity,
			deliveryDate: deliveryDate
			//orderDate: formatDate(dayjs(deliveryDate).add(-supplierLeadTime || 0, 'days'))
		};

		setRecalculateOnReset(true);
		updatedMovements[row.originalIndex] = newMovement;
		save(updatedMovements);

		toast.success(<ValidateSuccess
			currency={material.currency}
			ordersCount={material.calculation.genLotsOrdersCount}
			savings={material.calculation.pureSavings} />);


	};

	const remove = row => {
		const updatedMovements = movements.filter((_, idx) => idx !== row.originalIndex);
		setRecalculateOnReset(true);// make sure that we recalculate when reset
		save(updatedMovements);
	};

	const simulateUnfixed = row => {
		const updatedMovements = [...movements];
		updatedMovements[row.originalIndex] = {
			...movements[row.originalIndex],
			mrpElement: 'PurReq',
			revertTo: 'POItem'
		};
		setValue('movements', updatedMovements);
	};

	const addValue = () => {
		setValue('movements', [
			...movements,
			{
				deliveryDate: dayjs().format('YYYY-MM-DD'),
				mrpElement: 'PurReqGL',
				quantity: 0,
				supplier: _.get(material, 'suppliers[0].name', '-')
			}
		]);
	};

	const data = orderMovements.map((m, index) => ({
		...m,
		index,
		canBeValidated: m.mrpElement === 'PurReqGL',
		cancelForSimulation: config.fixedOrders.includes(m.mrpElement) && !['POItemGL', 'TPurReqGL', 'TPurReq'].includes(m.mrpElement)
	}));

	return (
		<ReactTable
			columns={columns}
			data={data}
			keyField="index"
			className={mergeClasses(styles.Table, '-compact', '-overflow')}
			showPagination={false}
			{...rest}>
			{(state, makeTable) => {
				const filteredState = { ...state, resolvedData: state.resolvedData.filter(o => o.mrpElement === 'POItemGL') };
				const numberPending = filteredState.resolvedData.length;

				return (
					<>
						<Grid container justifyContent="space-between">
							<Grid item>
								<ToggleButtonGroup exclusive onChange={handleChange} value={currentTab}>
									<ToggleButton value="GenLots">GenLots Optimized</ToggleButton>
									<ToggleButton value="SAP">Current Plan</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid>
								<ButtonGroup>
									<Button
										disabled={currentTab !== 'GenLots' || disabled}
										startIcon={<Add />}
										onClick={addValue}>
										Add
									</Button>
									<MenuButton disabled={disabled} startIcon={<GetAppIcon />} label="Download">
										<MenuItem onClick={() => downloadTableXLSX(state, `${material.number} ${material.name} - Orders`)}>All</MenuItem>
										<MenuItem disabled={!numberPending} onClick={() => {
											downloadTableXLSX(filteredState, `${material.number} ${material.name} - Pending orders`);
										}}>
											{`${numberPending} Pending orders`}
										</MenuItem>
									</MenuButton>
								</ButtonGroup>
							</Grid>
						</Grid>
						<Spacer size={2} />
						{makeTable()}
						{!recalculateOnReset && <Text className={styles.WarningOrdersText}>
							All unvalidated modifications will be lost if you simulate or reset
						</Text>
						}
						{recalculateOnReset && <Text className={styles.WarningOrdersText}>
							Recalculating will reset all suggestions (including validated ones) to the original suggestion
						</Text>
						}
					</>
				);
			}}
		</ReactTable>
	);
}

export default OrdersTable;
