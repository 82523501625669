import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectFeatures, selectUser } from 'modules/auth/selectors';

import { calculate, requestMaterial, setSimulatedMaterial, updateMaterial } from './actions';
import {
	selectFormMaterial,
	selectLoading,
	selectMaterial,
	selectMaterialSim,
	selectSentMaterial,
	selectSimulatedMaterial
} from './selectors';

const mapStateToProps = createStructuredSelector({
	material: selectMaterial(),
	formMaterial: selectFormMaterial(),
	materialSim: selectMaterialSim(),
	loading: selectLoading(),
	simulatedMaterial: selectSimulatedMaterial(),
	sentMaterial: selectSentMaterial(),
	features: selectFeatures(),
	user: selectUser()
});

const mapDispatchToProps = dispatch => bindActionCreators({ calculate, requestMaterial, setSimulatedMaterial, updateMaterial }, dispatch);

const enhancedComponent = (WrappedComponent) => (props) => {
	const { requestMaterial } = props;
	const { materialNumber } = useParams();

	useEffect(() => {
		requestMaterial(materialNumber);
	}, [requestMaterial, materialNumber]);

	const onSubmit = (formData) => {
		const parsedFormData = parseFormMaterial(formData);
		const dataToSend = prepareDataForSubmission(parsedFormData, props.material);
		props.setSimulatedMaterial(parsedFormData, formData, dataToSend);
		props.calculate(dataToSend);
	};

	const resetHandler = () => {
		props.requestMaterial(materialNumber);
	};

	return <WrappedComponent {...props} onSubmit={onSubmit} reset={resetHandler} />;
};

export default compose(
	withWidth(),
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	enhancedComponent
);

export const parseFormMaterial = material => {
	const parseFloatWComa = s => parseFloat(_.isString(s) ? s.replace(',', '.') : s);
	return({
	...material,
	orderCost: parseFloatWComa(material.orderCost),
	orderCostWeight: parseFloatWComa(material.orderCostWeight),
	carryingCostWeight: parseFloatWComa(material.carryingCostWeight),
	maxInventory: parseFloatWComa(material.maxInventory) || 0,
	maxCoverage: parseFloatWComa(material.maxCoverage) || 0,
	minimumOrderQuantity: parseFloatWComa(material.minimumOrderQuantity) || 0,
	maximumOrderQuantity: parseFloatWComa(material.maximumOrderQuantity) || 0,
	qualityControlLeadTime: parseFloatWComa(material.qualityControlLeadTime) || 0,
	phaseoutDuration: parseFloatWComa(material.phaseoutDuration) || 0,
	supplierLeadTime: parseFloatWComa(material.supplierLeadTime) || 0,
	serviceLevel: parseFloatWComa(material.serviceLevel) || 0,
	safetyStock: parseFloatWComa(material.safetyStock) || 0,
	safetyTime: parseFloatWComa(material.safetyTime) || 0,
	coverageDays: parseFloatWComa(material.coverageDays) || 0,
	fixedOrderSize: parseFloatWComa(material.fixedOrderSize) || 0,
	roundingValue: parseFloatWComa(material.roundingValue) || 0,
	shelfLife: parseFloatWComa(material.shelfLife) || 0,
	baseInventory: parseFloatWComa(material.baseInventory) || 0,
	shelfLifeInventory: material.shelfLifeInventory.map(el => ({ ...el, quantity: parseFloatWComa(el.quantity) || 0 })),
	accountingPrice: parseFloatWComa(material.accountingPrice) || null,
	pricePerUnitSingle: parseFloatWComa(material.pricePerUnitSingle) || 0,
	pricePerUnitMultiple: material.pricePerUnitMultiple.map(el => [el[0], parseFloatWComa(el[1]) || 0]),
	horizon: parseFloat(material.horizon) || null,
	consumptions: material.consumptions.map(el => parseFloatWComa(el) || 0),
	orders: material.orders.map(el => parseFloatWComa(el) || 0)
})}

export const prepareDataForSubmission = (formData, material) => {
	let data = { ...material, ...formData };

	// Fields used in subcomponent only and not to be sent
	delete data.quantityDiscountUnits;
	delete data.quantityDiscountPrice;
	delete data.newBatchNumber;
	delete data.newBatchQuantity;
	delete data.newBatchExpiryDate;
	delete data.newBatchStatusKey;
	delete data.orderMovements;


	// Don't send accountingPrice if not set ('')
	if (!data.accountingPrice) {
		delete data.accountingPrice;
	}

	if (data.activePricePerUnitMultiple) {
		data.pricePerUnit = data.pricePerUnitMultiple;
	} else {
		data.pricePerUnit = data.pricePerUnitSingle;
	}
	data.accountingPrice = data.pricePerUnitSingle;
	data.carryingCost /= 100;
	data.serviceLevel /= 100;


	data.fixed = data.fixed.map((f, i) => (f ? data.orders[i] : 0));

	if (data.initInventory === 'baseInventory') {
		delete data.shelfLifeInventory;
	}


	// Filter data that are toggled off
	const filters = {
		activeMinimumOrderQuantity: ['minimumOrderQuantity'],
		activeMaximumOrderQuantity: ['maximumOrderQuantity'],
		activeQualityControlLeadTime: ['qualityControlLeadTime'],
		activeSupplierLeadTime: ['supplierLeadTime'],
		activeFixedOrderSize: ['fixedOrderSize'],
		activeRoundingValue: ['roundingValue'],
		activeSafetyTime: ['safetyTime'],
		activeMaxInventory: ['maxInventory'],
		activeMaxCoverage: ['maxCoverage'],
		activeShelfLife: ['shelfLife', 'shelfLifeInventory'],
		activeTransportMode: ['transportMode'],
		activeWarehouse: ['warehouse'],
		activePhaseout: ['phaseout', 'phaseoutDuration']
	};

	Object.entries(filters).forEach(([activeFlag, fieldsToRemove]) => {
		if (!data[activeFlag]) {
			fieldsToRemove.forEach(field => delete data[field]);
		}
		delete data[activeFlag];
	});

	if (data.safetyStockFixedVariable === 'serviceLevel') {
		delete data.safetyStock;
		delete data.coverageDays;
	} else {
		delete data.serviceLevel;
	}

	if (data.shelfLifeInventory && data.shelfLifeInventory.length === 0) delete data.shelfLifeInventory;

	data = _.omit(data, [
		'activePricePerUnitMultiple',
		'calculation',
		'importDate',
		'infoRecord',
		'initInventory',
		'mrpController',
		'mrpType',
		'name',
		'ordersSAP',
		'palletConversion',
		'plantCode',
		'plantMaterialStatus',
		'procurementType',
		'procurementTypeCode',
		'purchasingGroup',
		'safetyStockFixedVariable',
		'shelfLifeWeight',
		'showConsumptions',
		'specialProcurementType',
		'specialProcurementTypeCode',
		'storageType',
		'tempCondition',
		'varianceType',
		'warehouseInfo'
	]);
	console.log('before submission for calulate', data);
	return data;
};
