import React from 'react';
import styles from '../../pages/ReportsPage/styles.scss';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

export const NoData = ({ title, message }) => {

	const messageText = message || 'No report data available';
	const titleText = title || 'Report';
	return (
		<div className={styles.ReportsPage}>
			<div className={styles.Header2}>
				<Grid container justifyContent="space-between">
					<Grid item>
						<div className={styles.Title}>{titleText}</div>
					</Grid>
				</Grid>
			</div>
			<div className={styles.Body}>
				<Alert severity="warning">
					{messageText}
				</Alert>
			</div>
		</div>
	);
};
