import _ from 'lodash';
import React from 'react';
import { Grid } from '@material-ui/core';
import MultiSelect from 'components/MultiSelect';
import styles from './styles.scss';

export const ChartFilter = ({ filters, options, setFilter }) => (
	<div className={styles.ChartFilter}>
		<Grid container spacing={2}>
			{_.map(
				{
					procurementTypeCode: 'Procurement type',
					purchasingGroup: 'Purchasing group',
					mrpType: 'MRP type',
					mrpController: 'MRP Controller',
					storageType: 'Storage type',
					number: 'Material'
				},
				(label, key) => (
					<Grid item key={key} xs={4}>
						<MultiSelect label={label} value={filters[key]} options={options[key]} onChange={value => setFilter(key, value)} />
					</Grid>
				)
			)}
		</Grid>
	</div>
);
