// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-Table-default-default thead tr th{border-bottom:1px solid #e0e0e0;vertical-align:middle;color:#9aa2a8;background-color:#fff;font-weight:normal;padding:7px 10px}.app-components-Table-default-default tbody tr{border-bottom:1px solid #f2f2f2 !important}.app-components-Table-default-default tbody tr td{padding:7px 10px}.app-components-Table-default-default tfoot tr td{background-color:#e1e1f7;border-bottom:none}", "",{"version":3,"sources":["webpack://./app/components/Table/default.scss"],"names":[],"mappings":"AAEC,kDACC,+BAAA,CACA,qBAAA,CACA,aAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CAGD,+CACC,0CAAA,CAEA,kDACC,gBAAA,CAIF,kDACC,wBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "app-components-Table-default-default"
};
export default ___CSS_LOADER_EXPORT___;
