import * as yup from 'yup';

const toNullIfEmptyString = (value, originalValue) => {
	return typeof originalValue === 'string' && originalValue?.trim() === '' ? null : value;
};

// Define the schema
export const schema = yup.object().shape({
	initInventory: yup.string().required(),
	baseInventory: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('initInventory', {
			is: (value) => value === 'baseInventory',
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	carryingCost: yup.number()
		.transform(toNullIfEmptyString)
		.min(0, 'Required'),
	coverageDays: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeCoverageDays', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	fixedOrderSize: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeFixedOrderSize', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	horizon: yup.number()
		.transform(toNullIfEmptyString)
		.min(0, 'Required'),
	maxCoverage: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeMaxCoverage', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	maxInventory: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeMaxInventory', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	maximumOrderQuantity: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeMaximumOrderQuantity', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	minimumOrderQuantity: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeMinimumOrderQuantity', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	orderCost: yup.number()
		.transform(toNullIfEmptyString)
		.min(0, 'Required'),
	pricePerUnitSingle: yup.number()
		.nullable()
		.when('activePricePerUnitMultiple', {
			is: (value) => value === false,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	qualityControlLeadTime: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeQualityControlLeadTime', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	roundingValue: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeRoundingValue', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	safetyStock: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('safetyStockFixedVariable', {
			is: (value) => value === 'safetyStock',
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	safetyTime: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeSafetyTime', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	serviceLevel: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('safetyStockFixedVariable', {
			is: (value) => value === 'serviceLevel',
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		}),
	supplierLeadTime: yup.number()
		.transform(toNullIfEmptyString)
		.nullable()
		.when('activeSupplierLeadTime', {
			is: (value) => value === true,
			then: (schema) => schema.min(0, 'Invalid'),
			otherwise: (schema) => schema.nullable()
		})
});

