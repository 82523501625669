import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MaterialNumberField } from 'components/input/MaterialTextField';
import { MaterialToggle } from 'components/input/MaterialToggle';
import tableStyle from 'components/Table/default.scss';
import { formatDate, frequencyToUnitSingular, labelToDate } from 'utils/dates';
import styles from './styles.scss';

function RequirementsTable({ material }) {
	const { control, watch } = useFormContext();

	// Initiate data
	const labels = watch('labels').map(l => formatDate(labelToDate(l, material.frequency)));
	const showConsumptions = watch('showConsumptions');
	const frequency = watch('frequency');

	return (
		<>
			<Grid container className={styles.HideEmptyRows}>
				<Grid item>
					<MaterialToggle name="showConsumptions" control={control} label="Show empty rows" />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs>
					<Table size="small" className={tableStyle.default}>
						<TableHead>
							<TableRow>
								<TableCell style={{ padding: 0 }} />
								<TableCell>{frequencyToUnitSingular(frequency)}</TableCell>
								<TableCell>Quantity ({material.unit})</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{labels &&
								labels.map((label, index) => {
									const consumptionValue = watch('consumptions')?.[index] || 0;
									if (
										showConsumptions ||
										(!showConsumptions && consumptionValue !== '' && consumptionValue !== '0')
									) {

										return (
											<TableRow key={label || index}>
												<TableCell className={styles.First} />
												<TableCell>{label}</TableCell>
												<TableCell>
													<MaterialNumberField control={control} name={`consumptions[${index}]`} inline />
												</TableCell>
											</TableRow>
										);
									}
									return null;
								})}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</>
	);
}

export default RequirementsTable;
