// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-button-LoadingButton-styles-CircularProgress{color:#fff !important}", "",{"version":3,"sources":["webpack://./app/components/button/LoadingButton/styles.scss"],"names":[],"mappings":"AACA,6DACC,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CircularProgress": "app-components-button-LoadingButton-styles-CircularProgress"
};
export default ___CSS_LOADER_EXPORT___;
