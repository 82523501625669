/**
 * Opportunities table selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectStorageTable = state => state.get('StorageTable') || initialState;

export const selectData = () =>
	createSelector(
		selectStorageTable,
		state => state.data
	);

export const selectLoading = () =>
	createSelector(
		selectStorageTable,
		state => state.loading
	);
