/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import DayJSUtils from '@date-io/dayjs';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'billboard.js/dist/billboard.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, Outlet, redirect, RouterProvider, useNavigate, useNavigation, useRouteError } from 'react-router-dom';
import { DataProvider } from 'contexts/DataContext';

import Header from 'containers/Header';
import injectRootSagas from 'modules/sagas';
import InventoryVisualizationPage from 'pages/InventoryVisualizationPage';
import LoginPage from 'pages/LoginPage/Loadable';
import MaterialPage from 'pages/MaterialPage/Loadable';
import NotFoundPage from 'pages/NotFoundPage/Loadable';
import OpportunitiesPage from 'pages/OpportunitiesPage/Loadable';
import OrdersPage from 'pages/OrdersPage';
import RecoverPage from 'pages/RecoverPage/Loadable';
import Co2Report from 'pages/ReportsPage/Co2Report';
import { ValueTracker } from 'pages/ReportsPage/ValueTracker';
import { loader as snapShotLoader, OrderTimeMachine } from 'pages/ReportsPage/ValueTracker/OrderTimeMachine';
import ResetPage from 'pages/ResetPage/Loadable';
import SafetyStockPage from 'pages/SafetyStockPage/Loadable';
import SimulatorCalculatingPage from 'pages/SimulatorCalculatingPage';
import { loader as simulatorEditionLoader, SimulatorEditionPage } from 'pages/SimulatorEditionPage';
import SimulatorPage from 'pages/SimulatorPage';
import { loader as simulatorResultsLoader, SimulatorResultsPage } from 'pages/SimulatorResultsPage';
import { loader as simulatorRoutingLoader, SimulatorRoutingPage } from 'pages/SimulatorRoutingPage';
import ExperimentsPage from 'pages/ExperimentsPage/Loadable';
import LoadingIndicator from 'components/LoadingIndicator';
import { comparisonListLoader, comparisonLoader, ordersLoader, reportsLoader } from 'loaders';
import theme from 'theme';
import Joyride from '../Joyride';
import styles from './styles.scss';
import { setNavigate } from 'modules/routing/navigateFn';

const Layout = () => {
	const navigation = useNavigation();
	const navigate = useNavigate();
	const [isNavigate, setIsNavigate] = useState(false);

	useEffect(() => {
		setNavigate(navigate);
		setIsNavigate(true);
	}, [navigate]);

	if (!isNavigate) {
		// Wait for navigate to be set, otherwise we can't navigate from outside React components
		return <LoadingIndicator />;
	}

	return (
		<div className={styles.App}>
			<Header />
			<Joyride />
			{(navigation.state === 'loading' || navigation.state === 'submitting') && <LoadingIndicator />}
			<DataProvider>
				<main className={styles.Main}>
					<Outlet />
				</main>
			</DataProvider>
		</div>
	);
};

export const ErrorElement = () => {
	const routeError = useRouteError();
	if (routeError?.response?.status === 404) {
		return (
			<Layout>
				<NotFoundPage />
			</Layout>);
	}
	return (
		<Layout>
			<h1 style={{ padding: '20px' }}>Something went wrong.</h1>
		</Layout>
	);
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
	injectRootSagas();

	const router = sentryCreateBrowserRouter([
		{
			path: '/',
			element: <Layout />,
			errorElement: <ErrorElement />,
			HydrateFallback: () => (
				<Layout>
					<LoadingIndicator />
				</Layout>
			),
			children: [
				{
					index: true,
					loader: () => redirect('/orders')
				},
				{
					path: '/orders',
					element: <OrdersPage />,
					loader: ordersLoader
				},
				{ path: '/reports/opportunities', element: <OpportunitiesPage /> },
				{
					path: '/reports/co2',
					element: <Co2Report />,
					loader: reportsLoader
				},
				{ path: '/material/:materialNumber', element: <MaterialPage /> },
				{ path: '/safetystock', element: <SafetyStockPage /> },
				{ path: '/inventory', element: <InventoryVisualizationPage /> },
				{ path: '/experiments', element: <ExperimentsPage /> },
				{
					path: '/reports/value-tracker',
					element: <ValueTracker />,
					loader: reportsLoader
				},
				{
					path: '/reports/value-tracker/:validatedMovementId',
					element: <OrderTimeMachine />,
					loader: snapShotLoader
				},
				{
					path: '/simulator',
					element: <SimulatorPage />,
					loader: comparisonListLoader
				},
				{
					path: '/simulator/:comparisonId',
					element: <SimulatorRoutingPage />,
					loader: simulatorRoutingLoader
				},
				{
					path: '/simulator/edit/:comparisonId',
					element: <SimulatorEditionPage />,
					loader: simulatorEditionLoader
				},
				{
					path: '/simulator/calculate/:comparisonId',
					element: <SimulatorCalculatingPage />,
					loader: comparisonLoader
				},
				{
					path: '/simulator/results/:comparisonId',
					element: <SimulatorResultsPage />,
					loader: simulatorResultsLoader
				},
				{ path: '/login', element: <LoginPage /> },
				{ path: '/reset', element: <ResetPage /> },
				{ path: '/recover', element: <RecoverPage /> },
				{ path: '/not-found', element: <NotFoundPage /> },
				{ path: '*', element: <NotFoundPage /> }
			]
		}
	], {
		future: {
			v7_startTransition: true,      // Uses React.useTransition for state updates
			v7_relativeSplatPath: true,    // Changes how relative paths work with splat routes (*)
			v7_fetcherPersist: true,       // Changes fetcher lifecycle as explained above
			v7_normalizeFormMethod: true,  // Makes HTTP methods uppercase (GET, POST, etc.)
			v7_partialHydration: true,     // Improves SSR hydration behavior
			v7_skipActionErrorRevalidation: true // Changes revalidation behavior after action errors
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<MuiPickersUtilsProvider utils={DayJSUtils}>
				<Sentry.ErrorBoundary fallback={ErrorElement}>
					<RouterProvider router={router} />
				</Sentry.ErrorBoundary>
				<ToastContainer />
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
}

export default App;
