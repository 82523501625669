/**
 * Opportunities table selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectStorageTable = state => state.get('StorageChart') || initialState;

export const selectResults = () =>
	createSelector(
		selectStorageTable,
		state => {
			const data = Object.assign({}, state.data.results);
			const toInvert = [
				'genLotsScrapPallets',
				'genLotsScrapValue',
				'comparisonScrapValue',
				'comparisonScrapPallets',
				'consumptionsValue',
				'consumptionsPallets'
			];
			toInvert.forEach(v => data[v] && (data[v] = data[v].map(i => -i))); // eslint-disable-line no-return-assign
			return data;
		}
	);

export const selectOptions = () =>
	createSelector(
		selectStorageTable,
		state => state.data.options
	);

export const selectLabels = () =>
	createSelector(
		selectStorageTable,
		state => state.data.labels || []
	);

export const selectLoading = () =>
	createSelector(
		selectStorageTable,
		state => state.loading
	);
