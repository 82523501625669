/**
 *
 * Section
 *
 */

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import React from 'react';

import { mergeClasses } from 'utils/classHelper';
import styles from './styles.scss';

const icon2icon = { clear: ClearIcon, play_arrow: PlayArrowIcon, check: CheckIcon };

const Section = ({ onReset, expanded, icon, title, children, onChange }) => {
	let _icon;
	if (icon) {
		const IconC = icon2icon[icon];
		_icon = (
			<div className={mergeClasses(styles.iconCircle, styles[icon])}>
				<div className={styles.Icon}>
					<IconC style={{ color: 'white', fontSize: 21 }} />
				</div>
			</div>
		);
	}

	return (
		<Accordion expanded={expanded} onChange={onChange}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				{_icon}
				<div className={styles.Title}>{title}</div>
				<div className={styles.Actions}>
					<IconButton
						size="small"
						onClick={e => {
							e.stopPropagation();
							onReset();
						}}>
						<RefreshIcon />
					</IconButton>
				</div>
			</AccordionSummary>
			<AccordionDetails className={styles.Body}>
				<div>{children}</div>
			</AccordionDetails>
		</Accordion>
	);
};

Section.propTypes = {
	children: PropTypes.node,
	icon: PropTypes.string,
	title: PropTypes.string.isRequired
};

export default Section;
