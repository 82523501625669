// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-InventoryVisualizationPage-ResultsSection-styles-ChartFilter{margin-left:50px;margin-bottom:20px;margin-right:50px}", "",{"version":3,"sources":["webpack://./app/pages/InventoryVisualizationPage/ResultsSection/styles.scss"],"names":[],"mappings":"AACA,wEACC,gBAAA,CACA,kBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChartFilter": "app-pages-InventoryVisualizationPage-ResultsSection-styles-ChartFilter"
};
export default ___CSS_LOADER_EXPORT___;
