import { put, takeLatest } from '@redux-saga/core/effects';

import { refreshStorageData } from '../ResultsSection/actions';
import { requestStorageType, UPDATE_STORAGE_TYPE } from './actions';

/**
 *
 */
export function* requestStorageTypeSaga() {
	yield put(requestStorageType());
	yield put(refreshStorageData());
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* storageTableSaga() {
	// Watches for UPDATE_STORAGE_TYPE actions and calls requestStorageTypeSaga when one comes in.
	// By using `takeLatest` only the result of the latest API call is applied.
	// It returns task descriptor (just like fork) so we can continue execution
	// It will be cancelled automatically on component unmount
	yield takeLatest(UPDATE_STORAGE_TYPE.SUCCESS, requestStorageTypeSaga);
}
