export const defaultColumns = [
	'number',
	'name',
	'mrpController',
	'suppliers',
	'firstOrder.orderDate',
	'firstOrder.quantity',
	'placeOrder',
	'supplierLeadTime',
	'availableInventory',
	'calculation.estimatedCoverage',
	'safetyStock',
	'serviceLevel',
	'calculation.pureSavings'
];

export const transferDownloadColumns = [
	'name',
	'number',
	'original.LabeledFamilyCenterId',
	'plantCode',
	'plantName',
	'transferData.sourceId',
	'transferData.sourceName',
	'transferData.quantity',
	'transferData.deliveryDate',
	'transferData.pickupDate',
	'transferData.transferSavingsBRL'
];
