import React, { useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigation } from 'react-router-dom';
import { useInjectReducer } from 'utils/injectReducer';
import TabPanel from 'components/TabPanel';
import LoadingIndicator from 'components/LoadingIndicator';
import { ReactTable } from 'components/ReactTable';
import { multipleEncode } from 'utils/url';
import { extractScenario } from 'modules/scenario';
import { Alerts } from 'modules/material';
import { useToggle, useToggleAll } from 'components/ReactTable/toggleColumns';
import FiltersBar, { applyFilters } from './FiltersBar';
import createColumns from './createColumns';
import reducer from './reducer';
import styles from './styles.scss';
import hoc from './hoc';

export const key = 'Orders';

const availableFilters = [
	{
		accessor: 'number',
		label: 'Material',
		limitTags: 2,
		filterFn: (row, options, { accessor }) => options.includes(String(accessor(row)))
	},
	{ accessor: 'mrpController', label: 'MRP Controller', limitTags: 3 },
	{
		id: 'supplier',
		getOptions: row => (row.suppliers ? row.suppliers.map(s => s.name) : []),
		label: 'Supplier',
		filterFn: (row, options) => row.suppliers && options.some(o => row.suppliers.map(s => s.name).includes(o))
	},
	{ accessor: 'mrpType', label: 'MRP Type', limitTags: 2 },
	{ accessor: 'procurementTypeCode', label: 'Procurement Type', limitTags: 2 },
	{ accessor: 'specialProcurementTypeCode', label: 'Special Procurement Type', limitTags: 2 },
	{ accessor: 'storageType', label: 'Storage Type', limitTags: 3 },
	{ accessor: 'abcIndicator', label: 'ABC Indicator', limitTags: 3 },
	{ accessor: 'warehouseInfo', label: 'Warehouse', limitTags: 2 },
	{ accessor: 'firstOrder.orderDate', label: 'Order week' },
	{
		id: 'alerts',
		label: 'Alerts',
		getOptions: row => Object.keys(Alerts).filter(a => row.planAlerts[a]),
		getOptionLabel: o => (Alerts[o] ? Alerts[o].label : o),
		filterFn: (row, options) => options.some(o => row.planAlerts[o])
	}
];

const applyAlertsFilters = (data, alerts) => data.filter(row => alerts.every(a => row.planAlerts[a]));

export function Orders({
												 features,
												 filters,
												 quickFilters,
												 setFilter,
												 setToggled,
												 toggled,
												 toggleFilter,
												 toggleQuickFilter,
												 width,
												 ...rest
											 }) {
	useInjectReducer({ key, reducer });
	document.title = 'Orders - GenLots';
	const navigation = useNavigation();
	const isLoading = navigation.state === 'loading';

	const { orders } = useLoaderData();

	const tableStateRef = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(0);
	const [currentPlan, setCurrentPlan] = useState('GenLots');


	const processedOrders = useMemo(() => {
		const mappedOrders = orders.map(o => ({
			...o,
			oCalculation: o.calculation,
		}));

		const scenarioOrders = extractScenario(
			mappedOrders,
			currentPlan === 'GenLots' ? 'genLots' : 'comparison'
		);

		// Process additional data
		scenarioOrders.forEach(o => {
			Object.assign(o.calculation, o.oCalculation);
			o.planAlerts = {...o.calculation.recommendedPlanAlerts, ...o.calculation.comparisonPlanAlerts};
			o.firstOrder = o.calculation.firstOrder;
		});

		return scenarioOrders;
	}, [orders, currentPlan]);


	const globalFilteredOrders = applyFilters(processedOrders, availableFilters, filters);
	const countAlerts = name => globalFilteredOrders.filter(o => o.planAlerts[name]).length;
	const filteredOrders = applyAlertsFilters(globalFilteredOrders, quickFilters);

	const totalCO2Transport = filteredOrders.reduce((sum, order) => sum + order.calculation.CO2Transport, 0);
	const totalCO2Warehouse = filteredOrders.reduce((sum, order) => sum + order.calculation.CO2Warehouse, 0);

	const columns = createColumns({
		width,
		features,
		totalCO2Transport,
		totalCO2Warehouse,
		toggled
	});

	const toggle = useToggle(toggled, setToggled);
	const toggleAll = useToggleAll(toggled, setToggled);

	return (
		<div className={styles.Order}>
			{isLoading && <LoadingIndicator />}
			<div className={styles.PageHeader}>
				<FiltersBar
					data={processedOrders}
					availableFilters={availableFilters}
					filters={filters}
					setFilter={setFilter}
					toggleFilter={toggleFilter}
					features={features}
					currentPlan={currentPlan}
					setCurrentPlan={setCurrentPlan}
					quickFilters={quickFilters}
					toggleQuickFilter={toggleQuickFilter}
					columns={columns}
					toggled={toggled}
					setToggled={setToggled}
					currentData={tableStateRef.current}
					toggle={toggle}
					toggleAll={toggleAll}
					countAlerts={countAlerts}
					setHeaderHeight={setHeaderHeight}
				/>
			</div>

			<div style={{ paddingTop: headerHeight }}>
				<TabPanel value={0} index={0} noOverflow>
					<ReactTable
						columns={columns}
						getTrProps={(state, row) => ({
							to: `/material/${multipleEncode(row.original.number)}`
						})}
						data={filteredOrders}
						toggle={toggle}
						toggled={toggled}
						pageText={<>{filteredOrders.length} Materials &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Page</>}
						toggleAll={toggleAll}
						{...rest}
						loading={isLoading}
					>
						{(state, makeTable) => {
							tableStateRef.current = state;
							return makeTable();
						}}
					</ReactTable>
				</TabPanel>
			</div>
		</div>
	);
}

export default hoc(Orders);
