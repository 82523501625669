import PropTypes from 'prop-types';
import React from 'react';

import Text from 'components/Text';
import styles from './styles.scss';

const Messages = ({ messages, label = null }) =>
	messages.length > 0 ? (
		<div className={styles.Messages}>
			{label && <div className={styles.Label}>{label}</div>}
			{messages.map(message => (
				<div key={message.label} className={message.level}>
					<Icon />
					<Text className={styles.Text}>{message.label}</Text>
				</div>
			))}
		</div>
	) : null;

Messages.propTypes = { messages: PropTypes.array, label: PropTypes.string };

export default Messages;

const Icon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24">
		<path fill="none" d="M0 0h24v24H0V0z" />
		<path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
);
