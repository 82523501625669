// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-OrdersTable-styles-Table .MuiSelect-select{padding-right:19px}.app-pages-MaterialPage-OrdersTable-styles-FirstColumn{padding:0 !important}.app-pages-MaterialPage-OrdersTable-styles-FirstColumn>div{width:5px;height:41px;padding:0 !important}.app-pages-MaterialPage-OrdersTable-styles-NoPadding{padding-top:0 !important;padding-bottom:0 !important}.app-pages-MaterialPage-OrdersTable-styles-WarningOrdersText{text-align:center;margin:20px;width:100%}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/OrdersTable/styles.scss"],"names":[],"mappings":"AACA,mEACC,kBAAA,CAGD,uDACC,oBAAA,CAEA,2DACC,SAAA,CACA,WAAA,CACA,oBAAA,CAIF,qDACC,wBAAA,CACA,2BAAA,CAGD,6DACC,iBAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Table": "app-pages-MaterialPage-OrdersTable-styles-Table",
	"FirstColumn": "app-pages-MaterialPage-OrdersTable-styles-FirstColumn",
	"NoPadding": "app-pages-MaterialPage-OrdersTable-styles-NoPadding",
	"WarningOrdersText": "app-pages-MaterialPage-OrdersTable-styles-WarningOrdersText"
};
export default ___CSS_LOADER_EXPORT___;
