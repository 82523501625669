// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-layout-Resizable-styles-Container{position:relative;width:100%}.app-components-layout-Resizable-styles-Panel{display:inline-block;vertical-align:top;position:relative}.app-components-layout-Resizable-styles-Panel>form{width:100%}.app-components-layout-Resizable-styles-Draggable{cursor:col-resize;position:absolute;top:0;padding:0 2px 0 5px;height:100%;z-index:1}.app-components-layout-Resizable-styles-Draggable>div{width:1px;height:100%;background:#d3d3d3}", "",{"version":3,"sources":["webpack://./app/components/layout/Resizable/styles.scss"],"names":[],"mappings":"AACA,kDACC,iBAAA,CACA,UAAA,CAGD,8CACC,oBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,mDACC,UAAA,CAIF,kDACC,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,mBAAA,CACA,WAAA,CACA,SAAA,CAEA,sDACC,SAAA,CACA,WAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "app-components-layout-Resizable-styles-Container",
	"Panel": "app-components-layout-Resizable-styles-Panel",
	"Draggable": "app-components-layout-Resizable-styles-Draggable"
};
export default ___CSS_LOADER_EXPORT___;
