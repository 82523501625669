// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-InventoryVisualizationPage-StorageTable-styles-Table .ReactTable .rt-tbody .rt-td{display:flex;flex-direction:row;justify-content:left}.app-pages-InventoryVisualizationPage-StorageTable-styles-SavingsPotential{text-align:right;color:#3b9173}.app-pages-InventoryVisualizationPage-StorageTable-styles-SavingsPotential.app-pages-InventoryVisualizationPage-StorageTable-styles-warning{color:#ffa900}.app-pages-InventoryVisualizationPage-StorageTable-styles-SavingsPotential.app-pages-InventoryVisualizationPage-StorageTable-styles-error{color:#f73000}", "",{"version":3,"sources":["webpack://./app/pages/InventoryVisualizationPage/StorageTable/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AAEC,6FACC,YAAA,CACA,kBAAA,CACA,oBAAA,CAIF,2EACC,gBAAA,CACA,aCWO,CDTP,4IACC,aCkBK,CDfN,0IACC,aCCI","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Table": "app-pages-InventoryVisualizationPage-StorageTable-styles-Table",
	"SavingsPotential": "app-pages-InventoryVisualizationPage-StorageTable-styles-SavingsPotential",
	"warning": "app-pages-InventoryVisualizationPage-StorageTable-styles-warning",
	"error": "app-pages-InventoryVisualizationPage-StorageTable-styles-error"
};
export default ___CSS_LOADER_EXPORT___;
