import { CALL_API, createRequestTypes } from 'modules/api';
import { getSearchParameter } from 'utils/url';

export const REQUEST_MATERIAL = createRequestTypes('REQUEST_MATERIAL');
export const requestMaterial = number => ({
	type: CALL_API,
	endpoint: `/company/${getSearchParameter('company') || 'me'}/material/${number}/order`,
	method: 'GET',
	callType: REQUEST_MATERIAL
});

export const CALCULATE = createRequestTypes('CALCULATE');
export const calculate = data => ({
	type: CALL_API,
	endpoint: '/calculate',
	method: 'POST',
	callType: CALCULATE,
	body: data
});

export const SET_SIMULATED_MATERIAL = 'SET_SIMULATED_MATERIAL';
export const setSimulatedMaterial = (material, formMaterial, sentMaterial) => ({
	type: SET_SIMULATED_MATERIAL,
	material,
	formMaterial,
	sentMaterial
});

export const UPDATE_MATERIAL = createRequestTypes('UPDATE_MATERIAL');
export const updateMaterial = (id, material) => ({
	type: CALL_API,
	endpoint: `/material/${id}`,
	method: 'POST',
	callType: UPDATE_MATERIAL,
	body: material
});
