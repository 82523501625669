import { CALL_API, createRequestTypes } from 'modules/api';
import { getSearchParameter } from 'utils/url';

export const REQUEST_STORAGE_TYPE = createRequestTypes('REQUEST_STORAGE_TYPE');
export const requestStorageType = () => ({
	type: CALL_API,
	endpoint: `/company/${getSearchParameter('company') || 'me'}/storage`,
	method: 'GET',
	callType: REQUEST_STORAGE_TYPE
});

export const UPDATE_STORAGE_TYPE = createRequestTypes('UPDATE_STORAGE_TYPE');
export const updateStorageType = storageType => {
	const { availabilities, name, type } = storageType;
	return {
		type: CALL_API,
		endpoint: `/storage/${storageType.id}`,
		method: 'POST',
		callType: UPDATE_STORAGE_TYPE,
		body: { availabilities, name, type }
	};
};
