// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-input-MaterialDatePicker-styles-MaterialDatePicker button{padding:3px;font-size:1rem}.app-components-input-MaterialDatePicker-styles-MaterialDatePicker button .MuiSvgIcon-root{font-size:1em}.app-components-input-MaterialDatePicker-styles-MaterialDatePicker .MuiInputAdornment-positionEnd{margin-left:3px}", "",{"version":3,"sources":["webpack://./app/components/input/MaterialDatePicker/styles.scss"],"names":[],"mappings":"AAGC,0EACC,WAAA,CACA,cAAA,CAEA,2FACC,aAAA,CAIF,kGACC,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialDatePicker": "app-components-input-MaterialDatePicker-styles-MaterialDatePicker"
};
export default ___CSS_LOADER_EXPORT___;
