// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-RequirementsTable-styles-HideEmptyRows{margin-bottom:10px}.app-pages-MaterialPage-RequirementsTable-styles-First{background:#00c2af;width:5px;height:41px;padding:0 !important}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/RequirementsTable/styles.scss"],"names":[],"mappings":"AACA,+DACC,kBAAA,CAGD,uDACC,kBAAA,CACA,SAAA,CACA,WAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HideEmptyRows": "app-pages-MaterialPage-RequirementsTable-styles-HideEmptyRows",
	"First": "app-pages-MaterialPage-RequirementsTable-styles-First"
};
export default ___CSS_LOADER_EXPORT___;
