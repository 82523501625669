import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React from 'react';

import ReactTable from 'components/ReactTable8';
import { formatUTCDateTime } from 'utils/dates';
import styles from '../styles.scss';

const supplierColumns = [
	{ header: 'Name', accessorKey: 'name', minWidth: 40 },
	{ header: 'Id', accessorKey: 'id', minWidth: 40 },
	{ header: 'Country', accessorKey: 'country', minWidth: 40 }
];

export default function({ material }) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Card variant="outlined">
					<CardHeader title="General" className={styles.InformationTitle} disableTypography />
					<CardContent>
						<Grid container>
							<Item name="MRP controller" value={material.mrpController} />
							<Item name="MRP type" value={material.mrpType} />
							<Item name="Purchasing group" value={material.purchasingGroup} />
							<Item name="ABC indicator" value={material.abcIndicator} />
							<Item name="Plant material status" value={material.plantMaterialStatus} />
							<Item name="Import" value={formatUTCDateTime(material.importDate)} />
						</Grid>
					</CardContent>
				</Card>
				<br />
				<Card variant="outlined">
					<CardHeader title="Procurement" className={styles.InformationTitle} disableTypography />
					<CardContent>
						<Grid container>
							<Item name="Procurement type" value={material.procurementTypeCode} />
							<Item name="Special procurement type" value={material.specialProcurementTypeCode} />
						</Grid>
					</CardContent>
				</Card>
				<br />
				{!_.isEmpty(material.original) && (
					<Card variant="outlined">
						<CardHeader title="Additional fields" className={styles.InformationTitle} disableTypography />
						<CardContent>
							<Grid container>
								{_.map(material.original, (value, key) => (
									<Item key={key} name={key} value={value} />
								))}
							</Grid>
						</CardContent>
					</Card>
				)}
			</Grid>
			<Grid item xs={6}>
				<Card variant="outlined">
					<CardHeader title="Warehouse" className={styles.InformationTitle} disableTypography />
					<CardContent>
						<Grid container>
							<Item name="Plant" value={material.plantCode} />
							<Item name="Warehouse" value={material.warehouseInfo} />
							<Item name="Storage" value={material.storageType} />
							<Item name="Temperature" value={material.tempCondition} />
						</Grid>
					</CardContent>
				</Card>
				<br />
				{material.suppliers && (
					<Card variant="outlined">
						<CardHeader title="Suppliers" className={styles.InformationTitle} disableTypography />
						<CardContent>
							<ReactTable data={material.suppliers} columns={supplierColumns} showPagination={false} />
						</CardContent>
					</Card>
				)}
			</Grid>
		</Grid>
	);
}

const Item = ({ name, value }) => (
	<>
		<Grid item xs={6} className={styles.InformationField}>
			{name}
		</Grid>
		<Grid item xs={6}>
			{value || '-'}
		</Grid>
	</>
);
