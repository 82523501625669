import { produce } from 'immer';
import { CALCULATE, REQUEST_MATERIAL, SET_SIMULATED_MATERIAL, UPDATE_MATERIAL } from './actions';
import { getPurReqGLfromCalculation, makeFormMaterial } from './helpers';

// The initial state
export const initialState = {
	loading: false,
	material: {},
	materialSim: {},
	simulatedMaterial: {},
	sentMaterial: {}
};

/* eslint-disable default-case */
const reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case REQUEST_MATERIAL.REQUEST:
				draft.material = {};
				draft.simulatedMaterial = {};
				draft.sentMaterial = {};
				draft.materialSim = {};
				draft.loading = true;
				break;
			case REQUEST_MATERIAL.SUCCESS:
				draft.material = action.response;
				draft.formMaterial = makeFormMaterial(action.response);
				draft.loading = false;
				break;
			case REQUEST_MATERIAL.FAILURE:
				draft.loading = false;
				break;
			case SET_SIMULATED_MATERIAL:
				draft.simulatedMaterial = action.material;
				draft.formMaterial = action.formMaterial;
				draft.sentMaterial = action.sentMaterial;
				break;
			case CALCULATE.REQUEST:
				draft.loading = true;
				break;
			case CALCULATE.SUCCESS:
				// eslint-disable-next-line no-case-declarations

				if (state.formMaterial.safetyStockFixedVariable === 'serviceLevel') {
					draft.formMaterial.safetyStock = action.response.safetyStockCalculated;
					draft.formMaterial.coverageDays = 0;
				} else {
					draft.formMaterial.serviceLevel = Math.round(action.response.serviceLevel * 100 * 100) / 100;
				}

				draft.formMaterial = {
					...state.formMaterial,
					movements: [...state.formMaterial.movements.filter(m => !['PurReqGL', 'TPurReqGL'].includes(m.mrpElement)),
						...getPurReqGLfromCalculation(action.response, state.formMaterial.frequency)]
				};
				draft.materialSim = action.response;
				draft.loading = false;
				break;
			case CALCULATE.FAILURE:
				draft.loading = false;
				break;
			case UPDATE_MATERIAL.REQUEST:
				draft.loading = true;
				break;
			case UPDATE_MATERIAL.SUCCESS:
				draft.loading = false;
				break;
			case UPDATE_MATERIAL.FAILURE:
				draft.loading = false;
				break;

		}
	});

export default reducer;
