import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { requestStorageType, updateStorageType } from './actions';
import { selectData, selectLoading } from './selectors';

const mapStateToProps = createStructuredSelector({
	data: selectData(),
	loading: selectLoading()
});

const mapDispatchToProps = dispatch => bindActionCreators({ requestStorageType, updateStorageType }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	);
