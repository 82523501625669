import React from 'react';

import hoc from './hoc';
import Messages from './Messages';
import styles from './styles.scss';

function Alerts({ comparisonPlanAlerts, recommendedPlanAlerts }) {
	return (
		<div className={styles.Alerts}>
			<Messages messages={recommendedPlanAlerts} label="GenLots plan alerts" />
			<Messages messages={comparisonPlanAlerts} label="Current plan alerts" />
		</div>
	);
}

export default hoc(Alerts);
