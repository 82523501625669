import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Assignment from '@material-ui/icons/Assignment';
import Business from '@material-ui/icons/Business';
import Eco from '@material-ui/icons/Eco';
import Settings from '@material-ui/icons/Edit';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Refresh from '@material-ui/icons/Refresh';

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import LoadingButton from 'components/button/LoadingButton';
import Resizable from 'components/layout/Resizable';
import LoadingIndicator from 'components/LoadingIndicator';
import { Icon } from 'components/Logo';
import PageHeader from 'components/PageHeader';
import TabPanel from 'components/TabPanel';
import AlertSvg from 'containers/results/images/icon-result-alert.svg';
import ComparisonSvg from 'containers/results/images/icon-result-comparison.svg';
import OverviewSvg from 'containers/results/images/icon-result-overview.svg';
import ResultsChart from 'containers/results/ResultsChart';
import ResultsWaterfall from 'containers/results/ResultsWaterfall';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import logger from 'utils/logger';
import Alerts from './Alerts';
import { CO2 } from './CO2';
import config from './config';
import hoc from './hoc';
import { MasterData } from './MasterData';
import MaterialInformation from './MaterialInformation';
import OrdersTable from './OrdersTable';
import reducer from './reducer';
import RequirementsTable from './RequirementsTable';
import styles from './styles.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validate';
import saga from './saga';
import { makeFormMaterial } from './helpers';

export const key = 'Material';

export function MaterialPage({
															 features,
															 formMaterial,
															 loading,
															 material,
															 materialSim,
															 onSubmit,
															 reset,
															 sentMaterial,
															 simulatedMaterial,
															 user,
															 updateMaterial,
															 isTimeMachine = false
														 }) {
	if (!isTimeMachine) {
		useInjectReducer({ key, reducer }); // Inject reducer
		useInjectSaga({ key, saga }); // Inject saga
	}

	const [leftTab, setLeftTab] = useState(0);
	const [rightTab, setRightTab] = useState(0);
	const [recalculateOnReset, setRecalculateOnReset] = useState(false);
	const [resetInProgress, setResetInProgress] = useState(false);

	const formMethods = useForm({
		resolver: yupResolver(schema),
		defaultValues: formMaterial || makeFormMaterial(material),
		shouldUnregister: false,
		shouldFocusError: true,
		mode: 'onBlur',
		criteriaMode: 'all'
	});

	const { handleSubmit, reset: resetForm, setValue } = formMethods;

	useEffect(() => {
		resetForm(formMaterial);
	}, [formMaterial]);

	logger.info('MaterialPage', material.number, material, formMaterial);
	logger.info('MaterialPage simulated:', materialSim, simulatedMaterial);
	document.title = `${material.number} ${material.name}`;

	const isSimulation = !isTimeMachine && 'totalSavings' in materialSim && 'id' in simulatedMaterial;
	const resultingMaterial = isSimulation ? { ...sentMaterial, calculation: materialSim } : material;

	if (!material.consumptions || !user) return <LoadingIndicator />;

	const countAlerts = obj => _.values(obj).filter(x => x).length;
	const alertsCount =
		countAlerts(resultingMaterial.calculation.comparisonPlanAlerts) + countAlerts(resultingMaterial.calculation.recommendedPlanAlerts);

	const resetSection = section => {
		const fieldsToReset = config[section].map(o => o.field);
		const d = _.pick(formMaterial, fieldsToReset);
		_.forEach(d, (v, k) => setValue(k, v));
	};
	const headerTextStyle = isTimeMachine ? { backgroundColor: '#56646E', color: '#FFFFFF' } : {};
	const headerHighlightStyle = isTimeMachine ? { color: '#FFFFFF' } : { color: '#5B63B6' };
	return (
		<div className={styles.Order}>
			{loading && <LoadingIndicator />}
			<PageHeader style={headerTextStyle} backTo={isTimeMachine ? "/reports/value-tracker" : "/orders"}>
				<h1 style={headerTextStyle}>
					<span style={headerHighlightStyle}>{material.number}</span>&nbsp;{material.name}&nbsp;
				</h1>
				{isSimulation && <Chip label="Simulation" color="primary" />}
				{isTimeMachine && <Chip label="Time Machine" color="$shuttleGray" />}
			</PageHeader>
			<Resizable
				ratio={0.4}
				right={
					<FormProvider {...formMethods}>
						<form noValidate onSubmit={handleSubmit(onSubmit)}>
							<Tabs value={rightTab} onChange={(event, newValue) => setRightTab(newValue)} className={styles.TabHeader}>
								<Tab className={styles.Tab} icon={<Assignment />} label="Orders" value={0} />
								<Tab className={styles.Tab} icon={<Business />} label="Requirements" value={1} />
								<Tab className={styles.Tab} icon={<Settings />} label="Master data" value={2} />
							</Tabs>
							<TabPanel value={rightTab} index={0} className={styles.TabPanel}>
								<OrdersTable
									config={user}
									features={features}
									material={material}
									updateMaterial={updateMaterial}
									recalculateOnReset={recalculateOnReset}
									setRecalculateOnReset={setRecalculateOnReset}
									resetInProgress={resetInProgress}
									setResetInProgress={setResetInProgress}
									loading={loading}
									reset={reset}
									disabled={isTimeMachine}
								/>
							</TabPanel>
							<TabPanel value={rightTab} index={1} className={styles.TabPanel}>
								<RequirementsTable material={material} disabled={isTimeMachine}/>
							</TabPanel>
							<TabPanel value={rightTab} index={2} className={styles.TabPanel}>
								<MasterData
									material={material}
									features={features}
									resetSection={resetSection}
								/>
							</TabPanel>
							<div className={styles.Footer}>
								<ButtonGroup size="large">
									<LoadingButton
										loading={loading}
										startIcon={<Icon className={styles.CalculateIcon} />}
										type="submit"
										variant="contained"
										disabled={isTimeMachine}>
										Simulate
									</LoadingButton>
									<Button startIcon={<Refresh />}
													onClick={() => {
														if (recalculateOnReset && !resetInProgress) {
															setResetInProgress(true);
														} else {
															reset();
														}
													}}
													disabled={isTimeMachine}
									>
										{recalculateOnReset ? 'Recalculate' : 'Reset'}
									</Button>
								</ButtonGroup>
							</div>
						</form>
					</FormProvider>

				}
				left={
					<>
						<Tabs value={leftTab} onChange={(event, newValue) => setLeftTab(newValue)} className={styles.TabHeader}>
							<Tab value={0} className={styles.Tab} icon={<img src={OverviewSvg} alt="Overview" />} label="Overview" />
							<Tab value={1} className={styles.Tab} icon={<img src={ComparisonSvg} alt="Comparison" />} label="Cost comparison" />
							{!!alertsCount && (
								<Tab
									value={2}
									className={styles.Tab}
									icon={<img src={AlertSvg} alt="Alerts" />}
									label={
										<Badge badgeContent={alertsCount} color="error" overlap="rectangular">
											Alerts
										</Badge>
									}
								/>
							)}
							<Tab className={styles.Tab} icon={<Eco />} label="CO2" value={4} />
							<Tab className={styles.Tab} icon={<HelpOutline />} label="Details" value={3} />
						</Tabs>
						<TabPanel value={leftTab} index={0}>
							<ResultsChart material={resultingMaterial} features={features} className={styles.BarChart} />
						</TabPanel>
						<TabPanel value={leftTab} index={1}>
							<ResultsWaterfall material={resultingMaterial} />
						</TabPanel>
						<TabPanel value={leftTab} index={2}>
							<Alerts material={resultingMaterial} />
						</TabPanel>
						<TabPanel value={leftTab} index={4}>
							<CO2 material={resultingMaterial} />
						</TabPanel>
						<TabPanel value={leftTab} index={3} className={styles.TabPanel}>
							<MaterialInformation material={resultingMaterial} />
						</TabPanel>
					</>
				}
			/>
		</div>
	);
}

export default hoc(MaterialPage);
