import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { dateToWeekShort } from 'utils/dates';
import styles from './styles.scss';
import sharedPropTypes from '../sharedPropTypes';

export const MaterialDatePicker = ({
																		 control,
																		 name,
																		 showErrors = true,
																		 className,
																		 defaultValue = dayjs().format('YYYY-MM-DD'),
																		 showWeekNumber,
																		 format = 'YYYY-MM-DD',
																		 rules,
																		 inline,
																		 ...rest
																	 }) => {
		if (inline) console.log('inline from ', name)
		return (
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={{ validate: value => dayjs(value, format, true).isValid()}}
				render={({ field: { value, onChange }, fieldState: { error } }) => {
					return (
						<>
							<KeyboardDatePicker
								autoOk
								className={styles.MaterialDatePicker}
								disableToolbar
								format={format}
								size="medium"
								variant="inline"
								value={value}
								error={!!(showErrors && error)}
								helperText={showErrors && error ? error.message : ''}
								onChange={date => onChange(dayjs(date).format(format))}
								{...rest}
							/>
							{showWeekNumber && value && (
								<i style={{ display: 'inline-block', margin: '5px 0px 0 7px ' }}>
									({dateToWeekShort(dayjs(value))})
								</i>
							)}
						</>
					);
				}
				}
			/>
		);
};

MaterialDatePicker.propTypes = { ...sharedPropTypes, showWeekNumber: PropTypes.bool };
