import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import React from 'react';

import styles from './styles.scss';

const LoadingButton = ({ loading, ...rest }) =>
	loading ? (
		<Button {..._.omit(rest, ['onClick', 'type'])} startIcon={<CircularProgress size={16} className={styles.CircularProgress} />} disabled />
	) : (
		<Button {...rest} />
	);

export default LoadingButton;
