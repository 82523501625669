import React from 'react';
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import _ from 'lodash';

import './react-table.scss';

// Helper to get column key (kept for compatibility)
export const columnKey = c => (c.id ? c.id : c.accessorKey);

// Base table configuration
export const TableDefault = {
	className: '-hover',
	keyField: 'id',
	minRows: 0,
	defaultColumn: {
		minSize: 40
	},
	enableSortingRemoval: false,
	enableMultiSort: false,
	enableColumnResizing: true
};

// Cell renderer helpers
export const booleanCell = ({ getValue }) => <Checkbox checked={getValue()} />;

const numberCell = (value, unit = '', format = formatShortNumber, className = null) => {
	if (value === null) {
		return <></>;
	}
	return (
		<>
			<div className={mergeClasses(styles.Number, className)}>{format(value)}</div>
			<div className={styles.Unit}>{unit}</div>
		</>
	);
};

export const coloredCell = (value, unit, format) =>
	numberCell(value, unit, format, value > 0 ? styles.Positive : value < 0 ? styles.Negative : null);

// Cell creator helpers
export const createNumberCell = (unit) => ({ getValue }) =>
	numberCell(getValue(), unit);

export const createCurrencyCell = () => ({ getValue, row }) =>
	numberCell(getValue(), row.original.currency, formatRoundedCurrency);

export const createExactCurrencyCell = () => ({ getValue, row }) =>
	numberCell(getValue(), row.original.currency, formatPrice);

export const createColoredCurrencyCell = () => ({ getValue, row }) =>
	coloredCell(getValue(), row.original.currency, formatRoundedCurrency);

export const createUnitCell = () => ({ getValue, row }) =>
	numberCell(getValue(), row.original.unit, formatShortQuantity);

export const createPercentCell = () => ({ getValue }) =>
	numberCell(100 * getValue(), '%', formatShortNumber);

export const createColoredPercentCell = () => ({ getValue }) =>
	coloredCell(100 * getValue(), '%', formatShortNumber);

export const createFrequencyCell = () => ({ getValue, row }) =>
	numberCell(getValue(), frequencyToUnit(row.original.frequency));

export const createCO2Cell = () => ({ getValue }) =>
	numberCell(getValue(), 'tC02e', formatCO2);

export const createDateCell = () => ({ getValue }) =>
	numberCell(getValue(), '', formatDate);

export const createSupplierCell = () => ({ getValue }) => {
	const value = getValue();
	if (value && value[0]) {
		const add = value.length > 1 ? ` (+${value.length - 1})` : '';
		const tooltip = value.map(i => i.name).join('\n');
		return (
			<span title={tooltip}>
        {value[0].name} {value[0].country ? `(${value[0].country})` : ''}
				{add}
      </span>
		);
	}
	return null;
};

export const createEllipsisCell = () => ({ getValue }) => {
	const value = getValue();
	return <span title={value}>{value}</span>;
};

// Footer helper
export const createFooter = (cellRenderer, totalFn = _.sum) => (info) => {
	const { table, column } = info;
	if (!table.getRowModel().rows.length) return '';

	const values = table.getRowModel().rows.map(row => row.getValue(column.id));
	const sum = totalFn(values);

	return cellRenderer()({
		getValue: () => sum,
		row: { original: table.getRowModel().rows[0].original }
	});
};

export const createCurrencyFooter = () => createFooter(createCurrencyCell);
export const createCurrencyAverageFooter = () => createFooter(createCurrencyCell, _.mean);
export const createCO2Footer = () => createFooter(createCO2Cell);

// Main table component
export const ReactTable = ({
														 columns,
														 data,
														 pagination = false,
														 sorting = true,
														 filtering = false,
														 className,
														 ...props
													 }) => {
	const table = useReactTable({
		columns,
		data,
		...TableDefault,
		...props,
		getCoreRowModel: getCoreRowModel(),
		...(sorting ? { getSortedRowModel: getSortedRowModel() } : {}),
		...(filtering ? { getFilteredRowModel: getFilteredRowModel() } : {}),
		...(pagination ? { getPaginationRowModel: getPaginationRowModel() } : {})
	});

	return (
		<div className={`ReactTable ${className || ''}`}>
			<table>
				<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th
								key={header.id}
								colSpan={header.colSpan}
								className={header.column.getIsSorted() ? `-sort-${header.column.getIsSorted()}` : ''}
							>
								<div onClick={header.column.getToggleSortingHandler()}>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</div>
								{filtering && header.column.getCanFilter() ? (
									<div>
										<input
											type="text"
											value={header.column.getFilterValue() ?? ''}
											onChange={e => header.column.setFilterValue(e.target.value)}
											placeholder=""
										/>
									</div>
								) : null}
							</th>
						))}
					</tr>
				))}
				</thead>
				<tbody>
				{table.getRowModel().rows.map(row => (
					<tr key={row.id} className={`rt-tr ${row.index % 2 === 0 ? '-odd' : '-even'}`}>
						{row.getVisibleCells().map(cell => (
							<td key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
				</tbody>
			</table>
			{pagination && (
				<div className="-pagination">
					<button
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
						className="-btn -previous"
					>
						<Tooltip title="Previous page">
							<ChevronLeftIcon />
						</Tooltip>
					</button>
					<button
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
						className="-btn -next"
					>
						<Tooltip title="Next page">
							<ChevronRightIcon />
						</Tooltip>
					</button>
					<select
						value={table.getState().pagination.pageSize}
						onChange={e => table.setPageSize(Number(e.target.value))}
					>
						{[10, 20, 30, 40, 50].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
	);
};

export default ReactTable;
