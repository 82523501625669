// Manage chart rendering and updating
import { useEffect, useRef } from 'react';
import { bb } from 'billboard.js';
import _ from 'lodash';

export function useChart({ config, data, containerId, type }) {
	const chartRef = useRef(null);

	useEffect(() => {
		if (!data || _.isEmpty(data)) return;

		const baseConfig = {
			bindto: `#${containerId}`,
			...config,
			data: {
				...config.data,
				type
			}
		};

		if (!chartRef.current) {
			chartRef.current = bb.generate(baseConfig);
		} else {
			chartRef.current.load({
				columns: baseConfig.data.columns,
				categories: baseConfig.axis?.x?.categories
			});
		}

		return () => {
			if (chartRef.current) {
				chartRef.current.destroy();
				chartRef.current = null;
			}
		};
	}, [config, data, containerId]);

	return chartRef.current;
}
