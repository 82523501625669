import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import FilterList from '@material-ui/icons/FilterList';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import UploadButton from 'components/button/UploadButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { defaultColumns, transferDownloadColumns } from './config';
import { SimplePageHeader } from 'components/PageHeader';
import MenuButton from 'components/button/MenuButton';
import { Checkbox } from 'components/Menu/ColumnMenu';
import MultiSelect from 'components/MultiSelect';
import MultiColumnMenu from 'components/Menu/MultiColumnMenu';
import StatusFilter from 'components/button/StatusFilter';
import { A } from 'components/Link';
import { downloadTableXLSX } from 'components/ReactTable/helpers';
import { toast } from 'react-toastify';
import logger from 'utils/logger';
import styles from './styles.scss';
import { useDataContext } from 'contexts/DataContext';
import { updateMaterials } from 'modules/api2/orders';

// Helper functions
const filterId = filter => (filter.id ? filter.id : _.isString(filter.accessor) ? filter.accessor : undefined);

const getAccessor = filter => {
	if (_.isString(filter.accessor)) {
		const path = filter.accessor;
		return row => _.get(row, path);
	}
	if (_.isFunction(filter.accessor)) return filter.accessor;
	return undefined;
};

const getOptions = (data, filter) => {
	const optionsGetter = filter.getOptions ? filter.getOptions : row => [filter.accessor(row)];
	return _.union(data.flatMap(optionsGetter).filter(x => x)).sort(filter.sortFn);
};

const DEFAULT_FILTER = {
	filterFn: (row, options, { accessor }) => _.includes(options, _.toString(accessor(row))),
	getOptions: undefined,
	getOptionLabel: undefined,
	sortFn: undefined
};

const filterAndCleanFilters = (filterConfigs, filters) =>
	filterConfigs
		.filter(f => _.includes(Object.keys(filters), filterId(f)))
		.map(f => Object.assign({}, DEFAULT_FILTER, f))
		.map(f => {
			if (!f.label) logger.error('Missing label in filter', f);
			if (!filterId(f)) logger.error('Cannot infer id for filter', f);
			if (!getAccessor(f) && !f.getOptions) logger.error('Cannot infer accessor and no getOptions for filter', f);
			return {
				...f,
				id: filterId(f),
				accessor: getAccessor(f)
			};
		});

const FiltersMenu = ({ label, checked, options, getOptionLabel, toggleFilter }) => (
	<MenuButton variant="outlined" color="primary" label={label} startIcon={<FilterList />}>
		{options.map(o => (
			<MenuItem key={o} onClick={() => toggleFilter(o)}>
				<Checkbox checked={checked.indexOf(o) > -1} label={getOptionLabel ? getOptionLabel(o) : o} />
			</MenuItem>
		))}
	</MenuButton>
);

const bulkUpdate = (e, company) => {
	const { files } = e.target;
	const file = files[0];
	const formData = new FormData();
	formData.append('file', file);
	updateMaterials(formData, company);
	e.target.value = '';
	toast.success('Batch update success');
};

export const FiltersBar = ({
														 data,
														 availableFilters,
														 filters,
														 setFilter,
														 toggleFilter,
														 features,
														 currentPlan,
														 setCurrentPlan,
														 quickFilters,
														 toggleQuickFilter,
														 columns,
														 setToggled,
														 currentData,
														 toggle,
														 toggleAll,
														 countAlerts,
														 setHeaderHeight
													 }) => {
	const filterRef = useRef(null);
	const { company, dashboardView, updateDataContext } = useDataContext();
	const companyName = company || 'GenLots';

	useEffect(() => {
		const updateHeight = () => {
			if (filterRef.current) {
				const headerElement = document.getElementById('orders-filters');
				if (headerElement) {
					const height = headerElement.getBoundingClientRect().height;
					setHeaderHeight(height - 5); // reduce height to get table closer
				}
			}
		};

		updateHeight();
		window.addEventListener('resize', updateHeight);
		return () => window.removeEventListener('resize', updateHeight);
	}, [filters, setHeaderHeight]);

	const cleanFilters = filterAndCleanFilters(availableFilters, filters);
	const resetColumns = () => setToggled([...defaultColumns]);

	return (
		<SimplePageHeader id="orders-filters" className={styles.PageHeader}>
			<div ref={filterRef} className={styles.FiltersContainer}>
				<div className={styles.Filters}>
					{cleanFilters.map(f => (
						<MultiSelect
							className={styles.Filter}
							key={filterId(f)}
							label={f.label}
							options={getOptions(data, f)}
							value={filters[filterId(f)]}
							onChange={v => setFilter(filterId(f), v)}
							getOptionLabel={f.getOptionLabel ? o => f.getOptionLabel(o) || o : undefined}
							PopperComponent={props => <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />}
							renderTags={(value, getTagProps) => {
								const numTags = value.length;
								const limitTags = f.limitTags || 1;
								return (
									<>
										{value.slice(0, limitTags).map((option, index) => (
											<Chip
												size="small"
												key={index}
												label={f.getOptionLabel ? f.getOptionLabel(option) : option}
												style={{ maxWidth: 'calc(100% - 30px)' }}
												{...getTagProps({ index })}
											/>
										))}
										{numTags > limitTags && ` +${numTags - limitTags}`}
									</>
								);
							}}
						/>
					))}
				</div>
				<div className={styles.MoreFilters}>
					<FiltersMenu
						label="Filters"
						checked={Object.keys(filters)}
						options={availableFilters.map(filterId)}
						getOptionLabel={o => availableFilters.find(f => filterId(f) === o).label}
						toggleFilter={toggleFilter}
					/>
				</div>
			</div>

			<div className={styles.ControlsContainer}>
				<div className={styles.PlanToggle}>
					<ToggleButtonGroup
						value={currentPlan}
						onChange={(event, newValue) => newValue && setCurrentPlan(newValue)}
						exclusive
					>
						<ToggleButton value="GenLots">GenLots Optimized</ToggleButton>
						<ToggleButton value="SAP">Current Plan</ToggleButton>
					</ToggleButtonGroup>
				</div>

				<div className={styles.StatusFilters}>
					<StatusFilter
						color="red"
						selected={quickFilters.includes('negativeAvailableStock')}
						onChange={() => toggleQuickFilter('negativeAvailableStock')}
					>
						Out of stock ({countAlerts('negativeAvailableStock')})
					</StatusFilter>
					<StatusFilter
						color="orange"
						selected={quickFilters.includes('belowSSAvailableStock')}
						onChange={() => toggleQuickFilter('belowSSAvailableStock')}
					>
						Below safety stock ({countAlerts('belowSSAvailableStock')})
					</StatusFilter>
					<StatusFilter
						color="blue"
						selected={quickFilters.includes('reorderDueToScrap')}
						onChange={() => toggleQuickFilter('reorderDueToScrap')}
					>
						Reorder because of Scrap ({countAlerts('reorderDueToScrap')})
					</StatusFilter>
				</div>

				{features.fullDashboard && (
					<div className={styles.DashboardSwitch}>
						<FormControlLabel
							label="All materials"
							className={styles.FullDashboardSwitch}
							control={
								<Switch
									checked={Boolean(dashboardView)}
									onChange={(event, checked) => {
										updateDataContext('dashboardView', checked ? features.fullDashboard : null);
									}}
								/>
							}
						/>
					</div>
				)}

				<div className={styles.Actions}>
					<ButtonGroup>
						<MenuButton startIcon={<GetAppIcon />} label="Download">
							<MenuItem onClick={() => downloadTableXLSX(currentData, `${companyName} - Orders`, { onlyVisible: true })}>
								This Table
							</MenuItem>
							<MenuItem onClick={() => downloadTableXLSX(currentData, `${companyName} - Orders`)}>
								All Columns
							</MenuItem>
							<MenuItem
								component={A}
								href={`${process.env.API_URL}/company/${company || 'me'}/report`}
								target="_blank"
							>
								Weekly transactions
							</MenuItem>
							<MenuItem
								component={A}
								href={`${process.env.API_URL}/company/${company || 'me'}/reports/pending-orders`}
							>
								Pending orders
							</MenuItem>
							{features.transfers && (
								<MenuItem
									onClick={() => {
										const data = {
											...currentData,
											resolvedData: currentData.resolvedData.filter(m => m['transferData.quantity'])
										};
										if (data.resolvedData.length === 0) {
											toast.error('No transfer orders');
										} else {
											downloadTableXLSX(data, `${companyName} - Transfer orders`, { columns: transferDownloadColumns });
										}
									}}
								>
									Transfer orders
								</MenuItem>
							)}
						</MenuButton>
						<MultiColumnMenu columns={columns} reset={resetColumns} onSelect={toggle} onSelectAll={toggleAll}>
							Columns
						</MultiColumnMenu>
						{features.batchUpdate && (
							<UploadButton onChange={e => bulkUpdate(e, company)} startIcon={<PublishIcon />}>
								Batch update
							</UploadButton>
						)}
						{features.batchUpdate && (
							<MenuButton style={{ minWidth: '20px', padding: 0 }} label={<ExpandMoreIcon />} endIcon={null}>
								<MenuItem component={A} href={`${process.env.API_URL}/static/Template phaseout.xlsx`} target="_blank">
									Download batch update template
								</MenuItem>
							</MenuButton>
						)}
					</ButtonGroup>
				</div>
			</div>
		</SimplePageHeader>
	);
};

export const applyFilters = (data, filterConfigs, filters) => {
	const validFilters = _.pickBy(filters, options => !_.isEmpty(options));
	const cleanFilters = filterAndCleanFilters(filterConfigs, validFilters);
	return data.filter(row =>
		_.every(validFilters, (options, id) => {
			const filter = cleanFilters.find(f => filterId(f) === id);
			return filter.filterFn(row, options, filter);
		})
	);
};

export default FiltersBar;
