import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { getSnapshot } from 'modules/api2/movements';
import { MaterialPage } from 'pages/MaterialPage';
import { useStoreContext } from 'contexts/StoreContext';


export async function loader({ request }) {
	const url = new URL(request.url);
	const validatedMovementId = url.pathname.split('/').pop();
	const snapshot = await getSnapshot(validatedMovementId);
	return { snapshot };
}

export function OrderTimeMachine() {
	const { snapshot } = useLoaderData();
	const { user, features } = useStoreContext();

	console.log(snapshot);
	return (
		<div>
			<MaterialPage material={snapshot} user={user} isTimeMachine={true} features={features}/>
		</div>
	);
}
