// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-components-input-MaterialToggle-styles-Label>span{color:#93989c !important;margin-left:8px !important}", "",{"version":3,"sources":["webpack://./app/components/input/MaterialToggle/styles.scss"],"names":[],"mappings":"AACA,uDACC,wBAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Label": "app-components-input-MaterialToggle-styles-Label"
};
export default ___CSS_LOADER_EXPORT___;
