import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { countries } from './countries';

export const AutocompleteField = ({
																		control,
																		name,
																		options,
																		getOptionSelected,
																		getOptionLabel,
																		renderOption,
																		onChange,
																		transformValue,
																		...rest
																	}) => (
	<Controller
		control={control}
		name={name}
		defaultValue=""
		render={({ field }) => (
			<Autocomplete
				options={options}
				fullWidth
				{...rest}
				{...field}
				autoHighlight
				getOptionSelected={getOptionSelected}
				getOptionLabel={getOptionLabel}
				renderOption={renderOption}
				renderInput={params => (
					<TextField {...params} fullWidth size="small" inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} /> // disable autocomplete and autofill
				)}
				onChange={(event, value) => {
					const transformedValue = transformValue ? transformValue(value) : value;
					field.onChange(transformedValue);
				}}
			/>
		)}
	/>
);

export const CountrySelect = ({ control, name, ...rest }) => (
	<AutocompleteField
		control={control}
		name={name}
		options={countries}
		getOptionSelected={(option, value) => value === '' || option.code === value}
		getOptionLabel={option => {
			const country = countries.find(o => o.code === option) || {};
			return option.label || country.label || option;
		}}
		renderOption={option => (
			<>
				{option.label} ({option.code})
			</>
		)}
		transformValue={value => value ? value.code : ''}
		{...rest}
	/>
);
