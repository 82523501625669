import React from 'react';
import { Alerts } from 'modules/material';

export default (WrappedComponent) => (props) => {
	const { material } = props;

	const comparisonPlanAlerts = Object.keys(material.calculation.comparisonPlanAlerts)
		.filter((key) => material.calculation.comparisonPlanAlerts[key])
		.map((key) => Alerts[key] || { level: 'info', label: key });

	const recommendedPlanAlerts = Object.keys(material.calculation.recommendedPlanAlerts)
		.filter((key) => material.calculation.recommendedPlanAlerts[key])
		.map((key) => Alerts[key] || { level: 'info', label: key });

	return (
		<WrappedComponent
			{...props}
			comparisonPlanAlerts={comparisonPlanAlerts}
			recommendedPlanAlerts={recommendedPlanAlerts}
		/>
	);
};

