import { getComparison, listComparisons } from 'modules/api2/comparison';
import { getOrders } from 'modules/api2/orders';
import { getReports } from 'modules/api2/reports';

export async function comparisonLoader({ params, searchParams = {} }) {
	const comparison = await getComparison(params.comparisonId, searchParams);
	return { comparison };
}

export async function comparisonListLoader({ request }) {
	const url = new URL(request.url);
	const company = url.searchParams.get('company');
	const comparisonList = await listComparisons(company);
	return { comparisonList };
}

export async function ordersLoader({ request }) {
	const url = new URL(request.url);
	const view = url.searchParams.get('dashboardView');
	const company = url.searchParams.get('company');
	const orders = await getOrders(company, { view });
	return { orders };
}

export async function reportsLoader({ request }) {
	const url = new URL(request.url);
	const type = url.pathname.split('/').pop(); // reports/opportunities -> opportunities
	const view = url.searchParams.get('dashboardView');
	const company = url.searchParams.get('company');
	const report = await getReports(type, company, { view });
	return { report };
}
