import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './styles.scss';

export const MaterialToggle = ({ name, control, className, label, onChange, ...rest }) => (
	<Controller
		control={control}
		name={name}
		render={({ field }) => (
			<FormControlLabel
				label={label}
				className={styles.Label}
				control={
					<Switch
						{...rest} checked={field.value || false}
						onChange={(event, value) => {
							field.onChange(value);
							if (onChange) onChange(value);
						}}
					/>
				}
			/>
		)}
	/>
);

MaterialToggle.propTypes = {
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func
};
