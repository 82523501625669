// navigateFn.js
let navigateFn;
let pendingNavigations = [];

export const setNavigate = (navigate) => {
	navigateFn = navigate;
	// Process any pending navigations
	while (pendingNavigations.length > 0) {
		const path = pendingNavigations.shift();
		navigateFn(path);
	}
};

export const navigate = (path) => {
	if (navigateFn) {
		navigateFn(path);
	} else {
		// Queue the navigation for when navigate becomes available
		pendingNavigations.push(path);
	}
};
