
const config = [
	[
		{ field: 'orderCost', default: 0 },
		{ field: 'orderCostWeight', default: 1 },
		{ field: 'carryingCost', default: 0 },
		{ field: 'carryingCostWeight', default: 1 },
		{ field: 'endOfYear', default: false },
		{ field: 'accountingPrice', default: '' },
		{ field: 'pricePerUnitSingle', default: '' },
		{ field: 'pricePerUnitMultiple', default: [] } // Active
	],
	[
		{ field: 'minimumOrderQuantity', default: '' }, // Active
		{ field: 'maximumOrderQuantity', default: '' }, // Active
		{ field: 'fixedOrderSize', default: '' }, // Active
		{ field: 'roundingValue', default: '' } // Active
	],
	[
		{ field: 'supplierLeadTime', default: '' }, // Active
		{ field: 'qualityControlLeadTime', default: '' }, // Active
		{ field: 'horizon', default: undefined }, // Active
		{ field: 'frequency', default: undefined } // Active
	],
	[
		{ field: 'safetyStock', default: '' }, // Active
		{ field: 'safetyTime', default: '' }, // Active
		{ field: 'coverageDays', default: '' }, // Active
		{ field: 'maxInventory', default: '' }, // Active
		{ field: 'maxCoverage', default: '' } // Active
	],
	[
		{ field: 'initOrderSize', default: 'fixedSize' },
		{ field: 'shelfLife', default: '' }, // Active
		{ field: 'baseInventory', default: '' }, // Active
		{ field: 'shelfLifeInventory', default: '' },
		{ field: 'initInventory', default: '' } // Active
	],
	[
		{ field: 'labels', default: [] }, // Active
		{ field: 'orders', default: [] },
		{ field: 'fixed', default: [] },
		{ field: 'hideOrders', default: false },
		{ field: 'strictAboutSafetyStock', default: false },
		// { field: 'consumptionsSAP', default: [] },
		{ field: 'ordersSAP', default: [] },
		{ field: 'consumptions', default: [] },
		{ field: 'showConsumptions', default: false } // Active
	]
];

export default config;

export const defaultValues = config.flat().reduce((acc, conf) => {
	acc[conf.field] = conf.default;
	return acc;
}, {});
