// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-Alerts-Messages-styles-Messages{margin-bottom:1rem;border:1px solid #e0e0e0;padding:16px}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .app-pages-MaterialPage-Alerts-Messages-styles-Label{padding:5px 0 16px;font-size:.8125rem}@media only screen and (min-width: 1400px){.app-pages-MaterialPage-Alerts-Messages-styles-Messages .app-pages-MaterialPage-Alerts-Messages-styles-Label{font-size:.9375rem}}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .app-pages-MaterialPage-Alerts-Messages-styles-Text{line-height:43px;color:#000;padding-left:12px}.app-pages-MaterialPage-Alerts-Messages-styles-Messages svg{vertical-align:middle}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .info svg{fill:#5b63b6}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .warn svg{fill:#ffa900}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .error svg{fill:#f73000}.app-pages-MaterialPage-Alerts-Messages-styles-Messages .success svg{fill:#3b9173}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/Alerts/Messages/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,wDACC,kBAAA,CACA,wBAAA,CACA,YAAA,CAEA,6GACC,kBAAA,CC8GD,kBAAA,CArDA,2CD1DA,6GCkHC,kBAAA,CAAA,CD7GD,4GACC,gBAAA,CACA,UAAA,CACA,iBAAA,CAGD,4DACC,qBAAA,CAGD,kEACC,YCVW,CDaZ,kEACC,YCMK,CDHN,mEACC,YCXI,CDcL,qEACC,YCZM","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Messages": "app-pages-MaterialPage-Alerts-Messages-styles-Messages",
	"Label": "app-pages-MaterialPage-Alerts-Messages-styles-Label",
	"Text": "app-pages-MaterialPage-Alerts-Messages-styles-Text"
};
export default ___CSS_LOADER_EXPORT___;
