import _ from 'lodash';
import { defaultValues } from './config';
import { formatLabel } from 'utils/dates';

/**
 * Returns the `initOrderSize` field value ("fixedSize" or "constrainedSize")
 * based on activation state of fields related to order size.
 *
 * @param data The form's state object
 */
export const isFixedOrConstrained = data => {
	if (data.activeFixedOrderSize) {
		return 'fixedSize';
	}
	if (data.activeRoundingValue || data.activeMinimumOrderQuantity || data.activeMaximumOrderQuantity) {
		return 'constrainedSize';
	}
	return 'fixedSize';
};

export const makeFormMaterial = material => {
	// console.log('makeFormMaterial', material, material.number);

	if (!material.number) return {};

	let data = { ...material };

	_.map(data, (i, k) => (!i ? defaultValues[k] : i));

	// Toggle single or multiple price
	data.pricePerUnitMultiple = data.pricePerUnitMultiple || [];
	data.activePricePerUnitMultiple = data.pricePerUnitMultiple.length > 0;
	data.pricePerUnitMultiple = data.pricePerUnitMultiple.map(price => [price[0], price[1].toString()]);

	// Material from form (simulation)
	const consumptions = data.consumptions.find(c => c.frequency === data.frequency);
	data.labels = consumptions.labels.slice(0);
	data.consumptions = consumptions.quantities.map(c => c.toString());

	const orders = data.orders.find(o => o.frequency === data.frequency);
	data.orders = orders.orders.map(o => o.toString());
	data.fixed = orders.fixed.map(x => x > 0);
	data.qmLots = orders.qmLots.slice(0);
	data.carryingCost = _.round(data.carryingCost * 100, 2);
	data.serviceLevel = _.round(data.serviceLevel * 100, 2);

	// Convert null => empty string
	// data = _.mapValues(data, (i) => (i === null ? '' : i));

	// Set activate variables
	const toActivate = [
		['minimumOrderQuantity', 'activeMinimumOrderQuantity'],
		['maximumOrderQuantity', 'activeMaximumOrderQuantity'],
		['safetyTime', 'activeSafetyTime'],
		// ['coverageDays', 'activeCoverageDays'],
		['qualityControlLeadTime', 'activeQualityControlLeadTime'],
		['supplierLeadTime', 'activeSupplierLeadTime'],
		['fixedOrderSize', 'activeFixedOrderSize'],
		['maxInventory', 'activeMaxInventory'],
		['maxCoverage', 'activeMaxCoverage'],
		['roundingValue', 'activeRoundingValue'],
		['shelfLife', 'activeShelfLife'],
		// ['shelfLifeInventory', 'activeShelfLifeInventory'],
		// ['baseInventory', 'activeBaseInventory'],
		['transportMode', 'activeTransportMode'],
		['warehouse', 'activeWarehouse'],
		['phaseout', 'activePhaseout']
	];
	toActivate.forEach(el => {
		data[el[1]] = !_.isNil(data[el[0]]) && data[el[0]] !== '' && data[el[0]] !== 0;
	});

	data.initOrderSize = isFixedOrConstrained(data);
	data.initInventory = data.shelfLifeInventory && data.shelfLifeInventory.length > 0 ? 'batches' : 'baseInventory';

	data = _.mapValues(data, i => (_.isNumber(i) ? i.toString() : i));

	return data;
};


export const getPurReqGLfromCalculation = (calculation, frequency) => {
	return calculation.genLotsDeliveries
		.map((delivery, i) => ({
			genLotsDelivery: delivery,
			fixedOrderDelivery: calculation.fixedOrderDeliveries[i],
			label: calculation.labels[i]
		}))
		.map(o => ({
			mrpElement: 'PurReqGL',
			quantity: o.genLotsDelivery - o.fixedOrderDelivery,
			deliveryDate: formatLabel(o.label, frequency)
		}))
		.filter(o => o.quantity > 0);
};
