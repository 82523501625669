import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { cancelAllRequests } from 'modules/api/sagas';
import { requestStorageData } from './actions';
import { selectLabels, selectLoading, selectOptions, selectResults } from './selectors';


const mapStateToProps = createStructuredSelector({
	data: selectResults(),
	options: selectOptions(),
	labels: selectLabels(),
	loading: selectLoading()
});

const mapDispatchToProps = dispatch => bindActionCreators({ requestStorageData }, dispatch);

const hocComponent = (WrappedComponent) => (props) => {
	const [filters, setFilters] = useState({});

	const setFilter = (name, value) => {
		const newFilters = { ...filters, [name]: value };
		setFilters(newFilters);
		cancelAllRequests();
		props.requestStorageData(newFilters);
	};

	useEffect(() => {
		props.requestStorageData();
	}, []);

	return (
		<WrappedComponent
			{...props}
			setFilter={setFilter}
			filters={filters}
		/>
	);
};

export default WrappedComponent => connect(mapStateToProps, mapDispatchToProps)(hocComponent(WrappedComponent));
