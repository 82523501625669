import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTable from 'components/ReactTable';
import { QuestionMarkTooltip } from 'components/Tooltip';
import { formatNumber } from 'utils/format';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { AvailabilityDialog } from './AvailabilityDialog';
import hoc from './hoc';
import reducer from './reducer';
import saga from './saga';
import styles from './styles.scss';

const key = 'StorageTable';

export function StorageTable({
															 data,
															 requestStorageType,
															 updateStorageType

														 }) {
	// Inject saga and reducer
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });

	const [expanded, setExpanded] = useState({});
	const [sorted, setSorted] = useState([]);
	const [dialogData, setDialogData] = useState(null);

	const openCreateDialog = (storageType) => setDialogData({ availability: {}, storageType });
	const openUpdateDialog = (storageType, availability, index) => setDialogData({ availability, storageType, index });
	const submitDialog = (availability) => {
		const { storageType, index } = dialogData;
		const newAvailabilities = index === undefined
			? [...storageType.availabilities, availability] // new availability
			: [...storageType.availabilities.slice(0, index), availability, ...storageType.availabilities.slice(index + 1)];  // edit existing

		updateStorageType({ ...storageType, availabilities: newAvailabilities });
		setDialogData(null);
	};
	const closeDialog = () => setDialogData(null);

	useEffect(() => {
		requestStorageType();
	}, [requestStorageType]);

	// Table columns
	const columns = [
		{
			expander: true,
			// width: 65,
			Expander: ({ isExpanded }) => <ChevronRightIcon style={isExpanded ? { transform: 'rotate(90deg)' } : {}} />,
			style: { cursor: 'pointer', fontSize: 25, padding: '7px', textAlign: 'center', userSelect: 'none' }
		},
		{ accessor: 'type', Header: 'Type', width: 110 },
		{ accessor: 'name', Header: 'Name' },
		{ accessor: 'occupation[0]', Header: 'Current occupation', Cell: formatPercent },
		{ accessor: 'averageOccupation', Header: 'Average occupation (12 month)', Cell: formatPercent },
		{
			accessor: 'maxOccupation',
			Header: 'Peak occupation',
			style: { overflow: 'visible' },
			Cell: ({ original: { labels, occupation } }) => {
				const value = _.max(occupation);
				return occupation && labels ? (
					<div style={{ textAlign: 'right' }}>
						<div style={{ display: 'inline-block', paddingRight: '4px' }}>{formatPercent({ value })}</div>
						<QuestionMarkTooltip title={`${formatNumber(value * 100, 1)}% in ${labels[occupation.indexOf(value)]}`} />
					</div>
				) : (
					'-'
				);
			}
		},
		{ accessor: 'capacities[0]', Header: 'Current capacity', Cell: row => <>{row.value} pallets</> }
	];

	return (
		<div className={styles.Table}>
			{/* Data table */}
			<ReactTable
				columns={columns}
				data={data}
				getTbodyProps={() => ({ style: { overflow: 'visible' } })}
				getTrProps={(state, { index }) => ({
					onClick: () => setExpanded(expanded[index] ? {} : { [index]: {} })
				})}
				defaultPageSize={40}
				expanded={expanded}
				onSortedChange={s => setSorted(s)}
				showPagination={false}
				sorted={sorted}
				SubComponent={({ original: storageType }) => {
					const subColumns = [
						{ accessor: 'start', Header: 'Start' },
						{ accessor: 'end', Header: 'End' },
						{ /* accessor: 'occupation[0]',, Cell: formatPercent */ Header: 'Current occupation' },
						{ /* accessor: 'averageOccupation',, Cell: formatPercent */ Header: 'Average occupation' },
						{ /* accessor: 'maxOccupation',, Cell: formatPercent */ Header: 'Peak occupation' },
						{ accessor: 'capacity', Header: 'Capacity' },
						{
							Cell: ({ index, original: availabilityRow }) => (
								<>
									<IconButton aria-label="edit" size="small" onClick={() => openUpdateDialog(storageType, availabilityRow, index)}>
										<EditIcon fontSize="inherit" />
									</IconButton>
									<IconButton
										aria-label="delete"
										size="small"
										onClick={() => {
											const newAvailabilities = storageType.availabilities.filter((_, i) => i !== index);
											updateStorageType({ ...storageType, availabilities: newAvailabilities });
										}}>
										<DeleteIcon fontSize="inherit" />
									</IconButton>
								</>
							)
						}
					];
					return (
						<div style={{ padding: '5px 0 15px 20px' }}>
							<ReactTable className="" columns={subColumns} data={storageType.availabilities} showPagination={false} />
							<Button variant="outlined" color="primary" size="small" onClick={() => openCreateDialog(storageType)}>
								Add
							</Button>
						</div>
					);
				}}
			/>
			<AvailabilityDialog
				availability={dialogData ? dialogData.availability : {}}
				onClose={closeDialog}
				onSubmit={submitDialog}
				open={!!dialogData}
			/>
		</div>
	);
}

StorageTable.propTypes = {
	closeDialog: PropTypes.func,
	data: PropTypes.array,
	dialogData: PropTypes.object,
	expanded: PropTypes.object,
	openCreateDialog: PropTypes.func,
	openUpdateDialog: PropTypes.func,
	setSorted: PropTypes.func,
	setExpanded: PropTypes.func,
	sorted: PropTypes.array,
	submitDialog: PropTypes.func,
	updateStorageType: PropTypes.func
};

function formatPercent(row) {
	const className = [styles.SavingsPotential];
	if (row.value > 1) {
		className.push(styles.error);
	} else if (row.value > 0.8) {
		className.push(styles.warning);
	}
	return <div className={className}>{`${formatNumber(row.value * 100, 1)} %`}</div>;
}

export default hoc(StorageTable);
