import { FormControlLabel, Grid, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { MaterialDatePicker } from 'components/input/MaterialDatePicker';
import { MaterialRadioGroup } from 'components/input/MaterialRadio';
import { MaterialSelectField } from 'components/input/MaterialSelectField';
import { MaterialNumberField, MaterialTextField } from 'components/input/MaterialTextField';
import { MaterialToggle } from 'components/input/MaterialToggle';
import { CountrySelect } from 'components/input/AutocompleteSelectField';
import { useFormContext } from 'react-hook-form';
import Section from 'components/Section';
import Text from 'components/Text';
import { QuestionMarkTooltip } from 'components/Tooltip';
import { Material } from 'modules/material';
import { mergeClasses } from 'utils/classHelper';
import { frequencyToUnit } from 'utils/dates';
import { uppercaseFirst } from 'utils/string';
import config from '../config';
import messages from '../messages';
import styles from './styles.scss';
import { QuantityDiscountsTable } from './QuantityDiscountTable';
import { BatchesTable } from './BatchesTable';


export const MasterData = injectIntl(({ material, features, intl, resetSection }) => {

	const [expanded, setExpanded] = useState();
	const { control, watch, setValue, formState: { errors}  } = useFormContext();



	const initOrderSize = watch('initOrderSize');

	useEffect(() => {
		if (initOrderSize === 'fixedSize') {
			setValue('activeFixedOrderSize', true);
		} else if (initOrderSize === 'constrainedSize') {
			setValue('activeFixedOrderSize', false);
		}
	}, [initOrderSize, setValue]);

	const { currency, unit: defaultUnit } = material;
	const frequency = frequencyToUnit(material.frequency);


	const toggleSection = panel => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

	const getFieldsForSection = section => config[section].map(o => o.field);
	const getIconForSection = (section) => {

		const fields = getFieldsForSection(section);
		const hasError = fields.find(f => errors && errors[f]);
		if (hasError) return 'clear';
		return 'check';
	};

	const FormInput = ({ className, labelType, name, disabled, unit, label, addedStyle }) => {
		/**Component which renders input fields with appropriate units and labels
		 * The types of labels (label.type) are:
		 * - '': a label with a tooltip,
		 * - 'padded': a label with a lot of padding, so that it is aligned with other fields which have toggles
		 * - 'radioInput': no label to fit into a radio group which has already labels
		 * - 'withToggle': a label with a toggle which can be switched on and off and controls the field active${Name}
		 */
		const materialFields = Material[name];
		const getLabel = () => {
			const effectiveLabel = label || materialFields.label;
			if (labelType === 'withToggle') {
				return <MaterialToggle className={styles.Toggle} name={`active${uppercaseFirst(name)}`}
															 label={effectiveLabel} control={control} />;
			}
			if (labelType === 'padded') return <>{'\u00A0'.repeat(13)}{effectiveLabel}{'\u00A0'}</>;
			return <>{effectiveLabel}{'\u00A0'}</>;
		};

		const getUnitLabel = () => {
			if (unit) return unit;
			if (materialFields.unit === 'unit') return defaultUnit;
			if (materialFields.unit === 'currency') return currency;
			if (materialFields.unit === 'frequency') return frequency;
			if (materialFields.unit) return materialFields.unit;
			return '';
		};

		const typeToField = { string: MaterialTextField, date: MaterialDatePicker, number: MaterialNumberField };
		const Cls = typeToField[materialFields.type];
		const additionalProps = materialFields.type === 'date' ? { showWeekNumber: true } : {};

		const InputField = () => {
			return (<Grid item className={mergeClasses(styles.Input, materialFields.type === 'number' ? styles.Small : null)} xs sm={6}
										style={addedStyle}>
					<Cls name={name} disabled={disabled} control={control} {...additionalProps} />
					<Text color={disabled ? 'disabled' : 'limedSpruce'}>{getUnitLabel()}</Text>
				</Grid>
			);
		};

		if (labelType === 'radioInput') return <InputField />;  // no label as directly integrated

		return (
			<Grid container className={mergeClasses(styles.Row, className)}>
				<Grid item xs sm={6}>
					{getLabel()}
					{materialFields.tooltip && <><QuestionMarkTooltip title={intl.formatMessage(materialFields.tooltip)} /></>}
				</Grid>
				<InputField />
			</Grid>
		);
	};

	return (
		<div className={styles.Body}>
			<Section
				expanded={expanded === 0}
				icon={getIconForSection(0)}
				title="Cost model"
				onChange={toggleSection(0)}
				onReset={() => resetSection(0)}>
				<FormInput name="orderCost" unit={`${currency} per order`} />
				<FormInput name="orderCostWeight" />

				<FormInput name="carryingCost" unit="% annually" />
				<FormInput name="carryingCostWeight" />

				<FormInput name="pricePerUnitSingle" disabled={watch('activePricePerUnitMultiple')} />

				<Grid container className={styles.Row} style={{ marginTop: '20px' }}>
					<Grid item>
						<MaterialToggle name="activePricePerUnitMultiple" label="Quantity discounts" control={control} />
					</Grid>
				</Grid>

				{watch('activePricePerUnitMultiple') && (
					<QuantityDiscountsTable intl={intl} />
				)}

			</Section>

			<Section
				expanded={expanded === 1}
				icon={getIconForSection(1)}
				title="Lot constraints"
				onChange={toggleSection(1)}
				onReset={() => resetSection(1)}>
				<Grid container className={styles.Row}>
					<Grid item xs sm={6}>
						<MaterialRadioGroup name="initOrderSize" control={control}>
							<FormControlLabel
								value="fixedSize"
								label={
									<>
										Fixed order size <QuestionMarkTooltip title={intl.formatMessage(messages.FixedOrderSizeHelp)} />
									</>
								}
							/>
							<FormControlLabel value="constrainedSize" label="Order size constraints" />
						</MaterialRadioGroup>
					</Grid>
					<Grid item xs={6} className={styles.Input} style={{ alignItems: 'flex-start' }}>
						<FormInput labelType="radioInput" name="fixedOrderSize" disabled={!watch('activeFixedOrderSize')}
											 control={control} />
					</Grid>
				</Grid>

				{watch('initOrderSize') === 'constrainedSize' && (
					<>
						<FormInput labelType="withToggle" name="minimumOrderQuantity" disabled={!watch('activeMinimumOrderQuantity')} />
						<FormInput labelType="withToggle" name="maximumOrderQuantity" disabled={!watch('activeMaximumOrderQuantity')} />
						<FormInput labelType="withToggle" name="roundingValue" disabled={!watch('activeRoundingValue')} />
					</>
				)}
				<Grid container className={styles.Row}>
					<Grid item xs sm={12}>
						<MaterialToggle name="strictAboutSafetyStock" label="Allow orders before fixed orders"
														control={control} />
					</Grid>
				</Grid>
			</Section>

			{/* Scheduling */}
			<Section
				expanded={expanded === 2}
				icon={getIconForSection(2)}
				title="Scheduling"
				onChange={toggleSection(2)}
				onReset={() => resetSection(2)}>
				<FormInput labelType="withToggle" name="supplierLeadTime" disabled={!watch('activeSupplierLeadTime')} />
				<FormInput labelType="withToggle" name="qualityControlLeadTime" disabled={!watch('activeQualityControlLeadTime')} />

				<FormInput labelType="padded" name="horizon" />
			</Section>

			{/* Safety settings */}
			<Section
				expanded={expanded === 3}
				icon={getIconForSection(3)}
				title="Safety settings"
				onChange={toggleSection(3)}
				onReset={() => resetSection(3)}>
				<MaterialRadioGroup name="safetyStockFixedVariable" className={styles.Radio} control={control}>
					<Grid container className={styles.Row}>
						<Grid item xs sm={6}>
							<FormControlLabel className={styles.RadioLabel} value="serviceLevel" label="Service level" />
						</Grid>
						<FormInput labelType="radioInput" name="serviceLevel" disabled={watch('safetyStockFixedVariable') !== 'serviceLevel'} />
					</Grid>
					<Grid container className={styles.Row}>
						<Grid item xs sm={6}>
							<FormControlLabel
								className={styles.RadioLabel}
								value="safetyStock"
								label={
									<>
										Safety stock <QuestionMarkTooltip title={intl.formatMessage(messages.SafetyStockHelp)} />
									</>
								}
							/>
						</Grid>
						<FormInput labelType="radioInput" name="safetyStock" unit={defaultUnit}
											 disabled={watch('safetyStockFixedVariable') !== 'safetyStock'} />
					</Grid>
				</MaterialRadioGroup>
				{features.coverageDays && (
					<FormInput labelType="padded" name="coverageDays" disabled={watch('safetyStockFixedVariable') !== 'safetyStock'} />
				)}
				<FormInput labelType="withToggle" name="safetyTime" disabled={!watch('activeSafetyTime')} />

				{features.maxInventory && <FormInput labelType="withToggle" name="maxInventory" disabled={!watch('activeMaxInventory')} />}
				{features.maxCoverage && (
					<>
						<FormInput labelType="withToggle" name="maxCoverage" disabled={!watch('activeMaxCoverage')} />
						<Grid container className={styles.Row}>
							<Grid item>
								<MaterialToggle name="strictMaxCoverage" label="Strict max. coverage" control={control} />
							</Grid>
						</Grid>
					</>
				)}
				{features.endOfYear && (
					<Grid container className={styles.Row}>
						<Grid item>
							<MaterialToggle name="endOfYear" label="End of year inventory reduction" control={control} />
						</Grid>
					</Grid>
				)}
			</Section>

			{/* Stock & expiry */}
			<Section
				expanded={expanded === 4}
				icon={getIconForSection(4)}
				title="Stock & expiry"
				onChange={toggleSection(4)}
				onReset={() => resetSection(4)}>
				<FormInput labelType="withToggle" name="shelfLife" disabled={!watch('activeShelfLife')} />
				<FormInput labelType="withToggle" name="phaseout" disabled={!watch('activePhaseout')} />
				<FormInput labelType="padded" name="phaseoutDuration" disabled={!watch('activePhaseout')} />
				<Grid container className={styles.Row}>
					<Grid item xs sm={6}>
						<MaterialRadioGroup name="initInventory" control={control}>
							<FormControlLabel
								value="baseInventory"
								label={
									<>
										Base Inventory <QuestionMarkTooltip title={intl.formatMessage(messages.BaseInventoryHelp)} />
									</>
								}
							/>
							<FormControlLabel
								value="batches"
								label={
									<>
										Batches <QuestionMarkTooltip title={intl.formatMessage(messages.BatchesHelp)} />
									</>
								}
							/>
						</MaterialRadioGroup>
					</Grid>
					<FormInput labelType="radioInput" name="baseInventory" disabled={watch('initInventory') === 'batches'} unit={defaultUnit}
										 addedStyle={{ marginTop: '-42px' }} />
				</Grid>

				{watch('initInventory') === 'batches' && (
					<BatchesTable />
				)}
			</Section>
			<Section
				expanded={expanded === 5}
				icon={getIconForSection(5)}
				title="CO2 Emissions"
				onChange={toggleSection(5)}
				onReset={() => resetSection(5)}>
				<Grid container className={styles.Row}>
					<Grid item xs sm={6}>
						<MaterialToggle className={styles.Toggle} name="activeTransportMode"
														label="Transport" defaultValue={watch('activeTransportMode')} control={control} />
					</Grid>
					<Grid item className={styles.Input} xs sm={6}>
						<MaterialSelectField name="transportMode" disabled={!watch('transportMode')} control={control}>
							<MenuItem value="truck">Truck</MenuItem>
							<MenuItem value="boat">Boat</MenuItem>
							<MenuItem value="plane">Plane</MenuItem>
						</MaterialSelectField>

					</Grid>
				</Grid>
				<Grid container className={styles.Row}>
					<Grid item xs sm={6} style={{ paddingLeft: '2.9rem' }}>
						Origin
					</Grid>
					<Grid item xs sm={6}>
						<CountrySelect name="suppliers[0].country" control={control} />
					</Grid>
				</Grid>

				<Grid container className={styles.Row}>
					<Grid item xs sm={6}>
						<MaterialToggle className={styles.Toggle} name="activeWarehouse"
														label="Warehouse" control={control} />
					</Grid>
					<Grid item xs sm={6}>
						<MaterialSelectField name="warehouse" fullWidth disabled={!watch('activeWarehouse')} control={control}>
							<MenuItem value="ambient">Standard</MenuItem>
							<MenuItem value="refrigerated">Refrigerated</MenuItem>
						</MaterialSelectField>
					</Grid>
				</Grid>
			</Section>
		</div>
	);
});
