import { produce}  from 'immer';
import { REQUEST_STORAGE_TYPE, UPDATE_STORAGE_TYPE } from './actions';

// The initial state
export const initialState = {
	loading: false,
	data: []
};

/* eslint-disable default-case, no-param-reassign */
const storageTable = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			// Get storage type reducers
			case REQUEST_STORAGE_TYPE.REQUEST:
			case UPDATE_STORAGE_TYPE.REQUEST:
				draft.loading = true;
				break;

			case REQUEST_STORAGE_TYPE.SUCCESS:
				draft.data = action.response;
				draft.loading = false;
				break;

			case REQUEST_STORAGE_TYPE.FAILURE:
			case UPDATE_STORAGE_TYPE.FAILURE:
				draft.loading = false;
				break;
		}
	});

export default storageTable;
