import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './styles.scss';

export const MaterialRadioGroup = ({ control, name, onChange, className, ...rest }) => (
	<Controller
		control={control}
		name={name}
		render={({ field }) => (
			<FormControl className={`${styles.Group} ${className}`}>
				<RadioGroup
					value={field.value || null} // undefined value sets component to uncontrolled
					onChange={(event, value) => {
						field.onChange(value);
						if (onChange) onChange();
					}}
					{...rest}
				/>
			</FormControl>
		)}
	/>
);

MaterialRadioGroup.propTypes = {
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	className: PropTypes.string,
	onChange: PropTypes.func
};
