/*
 *
 * Safety Stock Optimizer
 *
 */

import ExpandLessIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';

import Text from 'components/Text';
import OverviewSvg from 'containers/results/images/icon-result-overview.svg';
import OverviewSvg2 from 'containers/results/images/icon-result-recommendation.svg';
import { useInjectReducer } from 'utils/injectReducer';
import ResultsSection from './ResultsSection';
import reducer from './ResultsSection/reducer';
import StorageTable from './StorageTable';
import styles from './styles.scss';

function InventoryVisualizationPage() {
	useInjectReducer({ key: 'StorageChart', reducer });
	document.title = "Inventory Visualization - GenLots"

	return (
		<>
			{/* <PageHeader backTo="/orders" /> */}
			<div className={styles.Body}>
				<div className={styles.Title}>
					<img src={OverviewSvg2} role="presentation" className={styles.Logo} alt="Storage occupation logo" />
					<Text size="sectionTitle" color="white" className={styles.Text}>
						Storage Occupation
					</Text>
					<ExpandLessIcon className={styles.Toggle} />
				</div>
				<div className={styles.Content}>
					<StorageTable />
					<br />
				</div>
				<div className={styles.Title}>
					<img src={OverviewSvg} role="presentation" className={styles.Logo} alt="Inventory visualization logo" />
					<Text size="sectionTitle" color="white" className={styles.Text}>
						Inventory Visualization
					</Text>
					<ExpandLessIcon className={styles.Toggle} />
				</div>
				<div className={styles.Content}>
					<ResultsSection />
				</div>
			</div>
		</>
	);
}

export default InventoryVisualizationPage;
