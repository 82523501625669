import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaterialDatePicker } from 'components/input/MaterialDatePicker';
import { MaterialNumberField } from 'components/input/MaterialTextField';
import dayjs from 'dayjs';

const validationSchema = yup.object().shape({
	start: yup.date().required('Start date is required'),
	end: yup.date().nullable().min(yup.ref('start'), 'End date cannot be before start date'),
	capacity: yup.number().positive('Capacity must be a positive number').required('Capacity is required')
});

export function AvailabilityDialog({ availability, open, onClose, onSubmit }) {
	const { handleSubmit, control } = useForm({ resolver: yupResolver(validationSchema) });

	const handleFormSubmit = (data) => {
		data.start = dayjs(data.start).format('YYYY-MM-DD');
		data.end = dayjs(data.end).format('YYYY-MM-DD');
		if (data.end === 'Invalid Date') delete data.end;
		onSubmit(data);
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle id="form-dialog-title">{availability ? 'Edit' : 'Add'} availability</DialogTitle>
				<DialogContent style={{ maxWidth: '200px' }}>
					<MaterialDatePicker name="start" control={control} label="Start date" defaultValue={availability.start} />
					<MaterialDatePicker name="end" control={control} label="End date" defaultValue={availability.end || null} />
					<MaterialNumberField name="capacity" control={control} label="Capacity" defaultValue={availability.capacity} />
				</DialogContent>
				<DialogActions>
					<Button type="submit" variant="contained" color="primary">Send</Button>
					<Button onClick={onClose} color="primary">Cancel</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

AvailabilityDialog.propTypes = {
	availability: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};
