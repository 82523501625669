/*
 * Section2Form Messages
 *
 * This contains all the text for the Section2Form component.
 */
import { defineMessages } from 'react-intl';
import React from 'react';

export default defineMessages({
	OrderCostHelp: {
		id: 'app.components.Section2Form.OrderCostHelp',
		defaultMessage:
			'The cost associated with placing an order. (e.g. warehouse intake cost, quality control cost, and administrative cost). Order Cost is used to calculate the savings.'
	},
	OrderCostWeightHelp: {
		id: 'app.components.Section2Form.OrderCostWeightHelp',
		defaultMessage: 'The order cost used to find the optimal order plan can deviate from the one used for the calculation of savings'
	},
	CarryingCostHelp: {
		id: 'app.components.Section2Form.CarryingCostHelp',
		defaultMessage:
			'The cost associated with holding a material in storage. (e.g storage and capital costs). Carrying Cost is used to calculate the savings.'
	},
	CarryingCostWeightHelp: {
		id: 'app.components.Section2Form.CarryingCostWeightHelp',
		defaultMessage: 'The carrying cost used to find the optimal order plan can deviate from the one used to calculate the savings'
	},
	SafetyStockHelp: {
		id: 'app.components.Section3Form.SafetyStockHelp',
		defaultMessage: 'Safety stock in units. SAP field: MARC.EISBE'
	},
	SafetyTimeHelp: {
		id: 'app.components.Section3Form.SafetyTimeHelp',
		defaultMessage: 'Safety Time in workdays (time of order advancement). SAP field: MARC.SHZET'
	},
	CoverageDaysHelp: {
		id: 'app.components.Section3Form.CoverageDaysHelp',
		defaultMessage: 'Safety Stock calculated in coverage days'
	},
	FixedOrderSizeHelp: {
		id: 'app.components.Section3Form.FixedOrderSizeHelp',
		defaultMessage: 'Fixed order quantity. SAP field: MARC.BSTFE'
	},
	RoundingValueHelp: {
		id: 'app.components.Section3Form.RoundingValueHelp',
		defaultMessage: 'Rounding value for purchase order quantity. SAP field: MARC.BSTRF'
	},
	MinimumOrderQuantityHelp: {
		id: 'app.components.Section3Form.MinimumOrderQuantityHelp',
		defaultMessage: 'Minimum order quantity. SAP field: MARC.BSTMI'
	},
	MaximumOrderQuantityHelp: {
		id: 'app.components.Section3Form.MaximumOrderQuantityHelp',
		defaultMessage: 'Maximum order quantity. SAP field: MARC.BSTMA'
	},
	QualityControlLeadTimeHelp: {
		id: 'app.components.Section3Form.QualityControlLeadTimeHelp',
		defaultMessage: 'Goods receipt processing time in days. SAP field: MARC.WEBAZ'
	},
	SupplierLeadTimeHelp: {
		id: 'app.components.Section3Form.SupplierLeadTimeHelp',
		defaultMessage: 'Planned Delivery Time in Days. SAP field: MARC.PLIFZ'
	},
	ShelfLifeHelp: {
		id: 'app.components.Section3Form.ShelfLifeHelp',
		defaultMessage: 'Total shelf life. SAP field: MARA.MHDHB'
	},
	BaseInventoryHelp: {
		id: 'app.components.Section3Form.BaseInventoryHelp',
		defaultMessage: 'On Hand inventory. SAP field: MARC.STOCK'
	},
	BatchesHelp: {
		id: 'app.components.Section3Form.BatchesHelp',
		defaultMessage: 'Information about batches imported from SAP'
	},
	PricePerUnitHelp: {
		id: 'app.components.Section4Form.PricePerUnitHelp',
		defaultMessage: 'Price per unit'
	},
	StandardPriceHelp: {
		id: 'app.components.Section4Form.StandardPriceHelp',
		defaultMessage: 'Accounting price, calculated internally to value inventory. SAP field: MBEW.STPRS'
	},
	QuantityHelp: {
		id: 'app.components.Section4Form.QuantityHelp',
		defaultMessage: 'Condition scale quantity. SAP field: KONM.KSTBM'
	},
	PriceHelp: {
		id: 'app.components.Section4Form.PriceHelp',
		defaultMessage: 'Rate (condition amount). SAP field: KONM.KBETR'
	}
});


export const ValidateSuccess = ({ savings, ordersCount, currency }) => {
	const proRataSavings = ordersCount ? savings / ordersCount : 0;
	const proRataSavingsString = `${proRataSavings.toFixed(0)} ${currency}`
	return (
		<>
				<span style={{ fontWeight: 'bold', color: '#10B981'}}>
      + {proRataSavingsString}
    </span>
			{' '}saved! 🎉🎉🎉
		</>
	);
}
