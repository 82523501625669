// BarChart.js
import React from 'react';
import { bar } from 'billboard.js';
import _ from 'lodash';
import { capitalizeAll } from 'utils/format';
import { useChart } from './useChart';

const BarChart = ({
					  data,
					  containerId,
					  formatValue = value => value,
					  valueLabel = '',
					  height,
					  color = '#5B63B6',
					  columnLabel = 'Value'
				  }) => {
	const config = {
		axis: {
			rotated: true,
			x: {
				type: 'category',
				categories: _.map(_.keys(data), capitalizeAll),
				show: true,
				tick: { show: false }
			},
			y: {
				label: { text: valueLabel, position: 'outer-middle' }
			}
		},
		grid: {
			x: { show: false },
			y: {
				show: true,
				lines: [{ value: 0, class: 'base-line' }]
			}
		},
		data: {
			columns: [[columnLabel, ..._.values(data)]],
			colors: { [columnLabel]: color },
			labels: {
				format: { [columnLabel]: formatValue }

			},
		},
		tooltip: {
			format: { value: value => `${formatValue(value)}${valueLabel}` }
		},
		legend: { show: false },
		size: height ? { height } : undefined
	};

	useChart({ config, data, containerId, type: bar() });
	return <div id={containerId} className="bar-chart" />;
};

export default BarChart;
