import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LoadingIndicator from 'components/LoadingIndicator';
import { NoData } from 'components/Errors/NoData';
import { BarCard, DonutCard } from './Cards';
import { formatCO2 } from 'utils/format';
import styles from './styles.scss';

export const key = 'reports';

function Co2Report() {
	const { report: data } = useLoaderData();

	if (!data) return <LoadingIndicator />;

	document.title = 'CO2 Reports - GenLots';

	if (data.co2.transport === 0) {
		return (
			<NoData
				title={'CO2 Report'}
				message={'There are no results here as you probably have not updated pallet conversions in your Data or Units which cannot be converted to kg.'}
			/>
		);
	}

	return (
		<div className={styles.ReportsPage}>
			<div className={styles.Header2}>
				<Grid container justifyContent="space-between">
					<Grid item>
						<div className={styles.Title}>CO2 Report</div>
					</Grid>
				</Grid>
			</div>
			<div className={styles.Body}>
				<Grid container alignItems="flex-start" spacing={5}>
					<Grid item sm={6}>
						<DonutCard
							title="Emissions"
							data={data.co2}
							containerId="co2-chart"
							formatValue={formatCO2}
							valueLabel=" tCO2e"
						/>
					</Grid>

					<Grid item sm={6}>
						<DonutCard
							title="Transport mode"
							data={data.transportModes}
							containerId="co2-chart2"
							formatValue={formatCO2}
							valueLabel=" tCO2e"
						/>
					</Grid>

					<Grid item sm={6}>
						<BarCard
							title="Origin"
							data={data.continents}
							containerId="co2-chart3"
							formatValue={formatCO2}
							valueLabel=" tCO2e"
							columnLabel="GHG emissions"
						/>
					</Grid>

					<Grid item sm={6}>
						<BarCard
							title="Warehouse"
							data={data.warehouses}
							containerId="co2-chart4"
							formatValue={formatCO2}
							valueLabel=" tCO2e"
							columnLabel="GHG emissions"
							height={200}
						/>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

export default Co2Report;
