// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-ReportsPage-styles-ReportsPage{margin:0 auto}.app-pages-ReportsPage-styles-ReportsPage .app-pages-ReportsPage-styles-Grid{padding:12px}@media only screen and (min-width: 1400px){.app-pages-ReportsPage-styles-ReportsPage .app-pages-ReportsPage-styles-Grid{padding:16px}}.app-pages-ReportsPage-styles-ReportsPage .app-pages-ReportsPage-styles-Grid>div>div{margin-bottom:15px}.app-pages-ReportsPage-styles-Body{padding:26px}.app-pages-ReportsPage-styles-Header2{color:#7b87aa;font-size:26px;margin:0 10px;flex-grow:1;display:flex;align-items:center;background:#fafbff;padding:16px}.app-pages-ReportsPage-styles-Card{border-top-left-radius:5px !important;border-top-right-radius:5px !important}.app-pages-ReportsPage-styles-Card .app-pages-ReportsPage-styles-Header{border-top-left-radius:5px;border-top-right-radius:5px;height:7px;background-color:#5b63b6;padding:0 0 0 0 !important}.app-pages-ReportsPage-styles-Sub{font-size:22px}.app-pages-ReportsPage-styles-Unit{font-size:10px;letter-spacing:0;margin-left:2px;vertical-align:top}", "",{"version":3,"sources":["webpack://./app/pages/ReportsPage/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,0CACC,aAAA,CAEA,6EC8EA,YAAA,CAlBA,2CD5DA,6ECgFC,YAAA,CAAA,CD7EA,qFACC,kBAAA,CAKH,mCACC,YAAA,CAGD,sCACC,aAAA,CACA,cAAA,CACA,aAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,YAAA,CAGD,mCACC,qCAAA,CACA,sCAAA,CAEA,wEACC,0BAAA,CACA,2BAAA,CACA,UAAA,CACA,wBAAA,CACA,0BAAA,CAIF,kCACC,cAAA,CAGD,mCACC,cAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReportsPage": "app-pages-ReportsPage-styles-ReportsPage",
	"Grid": "app-pages-ReportsPage-styles-Grid",
	"Body": "app-pages-ReportsPage-styles-Body",
	"Header2": "app-pages-ReportsPage-styles-Header2",
	"Card": "app-pages-ReportsPage-styles-Card",
	"Header": "app-pages-ReportsPage-styles-Header",
	"Sub": "app-pages-ReportsPage-styles-Sub",
	"Unit": "app-pages-ReportsPage-styles-Unit"
};
export default ___CSS_LOADER_EXPORT___;
