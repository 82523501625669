// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-Alerts-styles-Alerts{display:flex;justify-content:stretch;margin:0 -8px}.app-pages-MaterialPage-Alerts-styles-Alerts>div{flex:1;margin:0 8px}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/Alerts/styles.scss"],"names":[],"mappings":"AACA,6CACC,YAAA,CACA,uBAAA,CACA,aAAA,CAEA,iDACC,MAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Alerts": "app-pages-MaterialPage-Alerts-styles-Alerts"
};
export default ___CSS_LOADER_EXPORT___;
