import React from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import Text from 'components/Text';
import { QuestionMarkTooltip } from 'components/Tooltip';
import { mergeClasses } from 'utils/classHelper';
import { formatInteger, formatShortNumber } from 'utils/format';
import { MaterialNumberField } from 'components/input/MaterialTextField';
import messages from '../../messages';
import styles from './styles.scss';

export const QuantityDiscountsTable = ({ intl }) => {
	const { control, watch, setValue, getValues, setError } = useFormContext();

	const quantityDiscountsWatch = watch('pricePerUnitMultiple', []);
	const quantityDiscounts = [...quantityDiscountsWatch].sort((a, b) => a[0] - b[0]);
	const currency = watch('currency', '');
	const unit = watch('unit', '');

	const addDiscount = (quantity, price) => {
		let newQuantityDiscounts = [...quantityDiscounts];
		const existingIndex = newQuantityDiscounts.findIndex(discount => discount[0] === quantity);

		if (existingIndex !== -1) {
			newQuantityDiscounts[existingIndex] = [quantity, price];
		} else {
			newQuantityDiscounts.push([quantity, price]);
		}
		newQuantityDiscounts.sort((a, b) => a[0] - b[0]);
		setValue('pricePerUnitMultiple', newQuantityDiscounts);
	};

	const deleteDiscount = (index) => {
		const newQuantityDiscounts = quantityDiscounts.filter((_, i) => i !== index);
		setValue('pricePerUnitMultiple', newQuantityDiscounts);
	};

	return (
		<div className={styles.QuantityDiscountTable}>
			<div className={styles.Header}>
				<div className={styles.Column}>
					Quantity ({unit}) <QuestionMarkTooltip title={intl.formatMessage(messages.QuantityHelp)} />
				</div>
				<div className={styles.Column}>
					Price ({currency}/{unit}) <QuestionMarkTooltip title={intl.formatMessage(messages.PriceHelp)} />
				</div>
				<div className={styles.Column} />
			</div>

			{quantityDiscounts.map((row, i) => (
				<div className={styles.Row} key={`discount-${i}`}>
					<div className={styles.Column}>
						<Text className={styles.Text}>
							{formatInteger(row[0])}
							{quantityDiscounts[i + 1] ? ' - ' : ''}
							{quantityDiscounts[i + 1] ? formatInteger(quantityDiscounts[i + 1][0]) : i > 0 ? '+' : ''}
						</Text>
					</div>
					<div className={styles.Column}>
						<Text className={mergeClasses(styles.Text, styles.price)}>{formatShortNumber(row[1], 4)}</Text>
					</div>
					<div className={styles.Column}>
						<Button variant="outlined" onClick={() => deleteDiscount(i)} fullWidth>
							<DeleteIcon color="action" />
						</Button>
					</div>
				</div>
			))}

			<div className={styles.Footer}>
				<div className={styles.Column}>
					<MaterialNumberField
						name="quantityDiscountUnits"
						label={`Quantity (${unit})`}
						type="number"
						control={control}
					/>
				</div>
				<div className={styles.Column}>
					<MaterialNumberField
						name="quantityDiscountPrice"
						label={`Price per ${unit}`}
						type="number"
						control={control}
					/>
					<Text color="gray">{currency}</Text>
				</div>
				<div className={styles.Column}>
					<Button
						color="primary"
						variant="outlined"
						size="small"
						onClick={() => {
							const quantity = getValues('quantityDiscountUnits');
							const price = getValues('quantityDiscountPrice');
							if (!quantity) setError('quantityDiscountUnits', { type: 'manual', message: 'Quantity is required' });
							if (!price) setError('quantityDiscountPrice', { type: 'manual', message: 'Price is required' });
							if (quantity && price) {
								addDiscount(quantity, price);
								setValue('quantityDiscountUnits', '');
								setValue('quantityDiscountPrice', '');
							}
						}}
						fullWidth
					>
						Add
					</Button>
				</div>
			</div>
		</div>
	);
};

