// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-pages-MaterialPage-MasterData-styles-Body{padding-left:16px}.app-pages-MaterialPage-MasterData-styles-Row{color:#93989c;margin-bottom:10px}.app-pages-MaterialPage-MasterData-styles-Row>div{align-items:center;display:flex}.app-pages-MaterialPage-MasterData-styles-Row .app-pages-MaterialPage-MasterData-styles-Input.app-pages-MaterialPage-MasterData-styles-Small>:first-child{width:80px}@media only screen and (max-width: 767px){.app-pages-MaterialPage-MasterData-styles-Row .app-pages-MaterialPage-MasterData-styles-Input.app-pages-MaterialPage-MasterData-styles-Small>:first-child{width:110px}}.app-pages-MaterialPage-MasterData-styles-Toggle{color:pink}.app-pages-MaterialPage-MasterData-styles-Radio{width:100%}.app-pages-MaterialPage-MasterData-styles-Radio>div{width:100%}.app-pages-MaterialPage-MasterData-styles-RadioLabel>:first-child{padding-right:18px;padding-left:18px}", "",{"version":3,"sources":["webpack://./app/pages/MaterialPage/MasterData/styles.scss","webpack://./app/style/variables.scss"],"names":[],"mappings":"AACA,+CACC,iBAAA,CAGD,8CACC,aCoBU,CDnBV,kBAAA,CAEA,kDACC,kBAAA,CACA,YAAA,CAGD,0JACC,UAAA,CAEA,0CAHD,0JAIE,WAAA,CAAA,CAaH,iDACC,UAAA,CAGD,gDACC,UAAA,CAEA,oDACC,UAAA,CAIF,kEACC,kBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Body": "app-pages-MaterialPage-MasterData-styles-Body",
	"Row": "app-pages-MaterialPage-MasterData-styles-Row",
	"Input": "app-pages-MaterialPage-MasterData-styles-Input",
	"Small": "app-pages-MaterialPage-MasterData-styles-Small",
	"Toggle": "app-pages-MaterialPage-MasterData-styles-Toggle",
	"Radio": "app-pages-MaterialPage-MasterData-styles-Radio",
	"RadioLabel": "app-pages-MaterialPage-MasterData-styles-RadioLabel"
};
export default ___CSS_LOADER_EXPORT___;
