import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { bb, donut } from 'billboard.js';
import React, { useEffect } from 'react';

import { formatShortNumber } from 'utils/format';
import styles from './styles.scss';

function genCO2Chart(m) {
	const c = m.calculation;
	window.chart = bb.generate({
		bindto: `#co2-chart`,
		data: {
			columns: [
				// ['productCarbonFootprint', c.genLotsCO2Footprint / c.genLotsCO2],
				['transport', c.genLotsCO2Transport / c.genLotsCO2],
				['warehouse', c.genLotsCO2Warehouse / c.genLotsCO2]
			],
			colors: {
				// productCarbonFootprint: '#343174',
				transport: '#5B63B6',
				warehouse: '#acaec7'
			},
			type: donut(),
			names: {
				// productCarbonFootprint: 'Product carbon footprint',
				transport: 'Transport',
				warehouse: 'Warehouse'
			}
		}
	});
}

export function CO2({ material }) {
	useEffect(() => {
		genCO2Chart(material);
	});

	const c = material.calculation;
	const Unit = () => <span className={styles.Unit}>tCO2e</span>;
	let alert;
	if (['kg', 'g', 'l', 'ml', 'biu'].indexOf(material.unit.toLowerCase()) === -1) {
		alert = `Unable to compute CO2 consumption for unit: ${material.unit}.`;
	}

	return (
		<>
			{alert && <Alert severity="warning">{alert}</Alert>}

			<Grid container alignItems="center">
				<Grid item sm={5}>
					<div id="co2-chart" />
				</Grid>
				<Grid item sm={6}>
					<Grid container className={styles.Results}>
						<Grid item sm={4}>
							<div>&nbsp;</div>
							{/* <div>Product carbon footprint</div> */}
							<div>Transport</div>
							<div>Warehouse</div>
							<div>
								<b>Total</b>
							</div>
						</Grid>
						<Grid item sm={4} style={{ textAlign: 'right' }}>
							<div>GenLots</div>
							{/* <div>{formatShortNumber(c.genLotsCO2Footprint, 3)} <Unit /></div> */}
							<div>
								{formatShortNumber(c.genLotsCO2Transport, 3)}
								<Unit />
							</div>
							<div>
								{formatShortNumber(c.genLotsCO2Warehouse, 3)}
								<Unit />
							</div>
							<div>
								<b>
									{formatShortNumber(c.genLotsCO2, 3)}
									<Unit />
								</b>
							</div>
						</Grid>
						<Grid item sm={4} style={{ textAlign: 'right' }}>
							<div>Current Plan</div>
							{/* <div>{formatShortNumber(c.comparisonCO2Footprint, 3)}<Unit /></div> */}
							<div>
								{formatShortNumber(c.comparisonCO2Transport, 3)}
								<Unit />
							</div>
							<div>
								{formatShortNumber(c.comparisonCO2Warehouse, 3)}
								<Unit />
							</div>
							<div>
								<b>
									{formatShortNumber(c.comparisonCO2, 3)}
									<Unit />
								</b>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
