import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import DonutChart from 'components/charts/DonutChart';
import BarChart from 'components/charts/BarChart';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import { uppercaseFirst } from 'utils/string';
import styles from './styles.scss';

const CustomTable = ({ data, formatValue, valueLabel }) => (
	<Table>
		<TableBody>
			{_.map(data, (value, key) => (
				<TableRow key={key}>
					<TableCell>{uppercaseFirst(key)}</TableCell>
					<TableCell align="right">
						{formatValue(value)} <span className={styles.Unit}>{valueLabel}</span>
					</TableCell>
				</TableRow>
			))}
			<TableRow>
				<TableCell>Total</TableCell>
				<TableCell align="right">
					{formatValue(_.sum(Object.values(data)))} <span className={styles.Unit}>{valueLabel}</span>
				</TableCell>
			</TableRow>
		</TableBody>
	</Table>
);

export function DonutCard({ title, data, containerId, formatValue, valueLabel }) {
	return (
		<Card className={styles.Card}>
			<CardHeader className={styles.Header}>{title}</CardHeader>
			<CardContent className={styles.Card}>
				<div className={styles.Sub}>{title}</div>
				<Grid container alignItems="center">
					<Grid item xs={6}>
						<DonutChart
							data={data}
							containerId={containerId}
							formatValue={formatValue}
							valueLabel={valueLabel}
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomTable
							data={data}
							formatValue={formatValue}
							valueLabel={valueLabel}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export function BarCard({ title, data, containerId, formatValue, valueLabel, columnLabel, height }) {
	return (
		<Card className={styles.Card}>
			<CardHeader className={styles.Header}>{title}</CardHeader>
			<CardContent className={styles.Card}>
				<div className={styles.Sub}>{title}</div>
				<BarChart
					data={data}
					containerId={containerId}
					formatValue={formatValue}
					valueLabel={valueLabel}
					columnLabel={columnLabel}
					height={height}
				/>
			</CardContent>
		</Card>
	);
}
