import {throttle} from 'lodash';
import React, {useEffect} from 'react';
import Draggable from 'react-draggable';

import styles from './styles.scss';

let lastData = { lastX: 0 };

const Resizable = ({ left, right, ratio = 0.5 }) => {
	let container;
	let width;
	const onDragStart = () => {
		container = document.getElementById('container-resizable');
		width = container ? container.clientWidth : 0;
	};

	const onDrag = (e, data) => {
		container.childNodes[0].style.width = `${Math.floor((1 - ratio) * width + data.lastX)}px`;
		container.childNodes[1].style.width = `${Math.floor(ratio * width - data.lastX)}px`;
		if (window.chart) {
			window.chart.flush();
		}
		lastData = data;
	};
	const onDragThrottled = throttle(onDrag, 20);

	useEffect(() => {
		const listener = throttle(() => {
			onDragStart();
			onDrag(null, lastData);
		}, 20);
		window.addEventListener('resize', listener, true);
		return () => window.removeEventListener('resize', listener, true);
	}, []);

	return (
		<div id="container-resizable" className={styles.Container}>
			<div style={{ width: `${(1 - ratio) * 100}%` }} className={styles.Panel}>
				{left}
			</div>
			<div style={{ width: `${ratio * 100}%` }} className={styles.Panel}>
				{right}
			</div>
			<Draggable axis="x" onStart={onDragStart} onDrag={onDragThrottled} onStop={onDragThrottled} bounds="parent">
				<div className={styles.Draggable} style={{ left: `calc(${(1 - ratio) * 100}% - 5px)` }}>
					<div />
				</div>
			</Draggable>
		</div>
	);
};

export default Resizable;
